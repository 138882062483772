import { useState, useRef, useEffect, useContext } from "react";
import { Container, Form, Button, Col, Row, Spinner } from "react-bootstrap";
//import scss file
import "../../../../../App.scss";
import {
  MM_CRM_LOCATION_ID,
  ST_CRM_LOCATION_ID,
} from "../../../../../integrations/Highlevel/constants";
import { API_BASE_URL } from "../../../../../utils/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { trackCustomAction } from "../../../api";
// import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import LocationContext from "../../../../../context/Locations/LocationContext";

const ArtistProfile = ({ setCompleted }) => {
  const {locationData, refreshData} = useContext(LocationContext);
  const [artistName, setArtistName] = useState(locationData?.name ?? "");
  const [primaryGenre, setPrimaryGenre] = useState(locationData?.genre ?? "");
  const [subGenres, setSubGenres] = useState(locationData?.subgenres ?? []);
  const [bio, setBio] = useState(
    locationData?.custom_values?.["Artist Bio Short"] ?? ""
  );
  const [generateBio, setGenerateBio] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState({
    q1: "",
    q2: "",
    q3: "",
  });
  const [socials, setSocials] = useState({
    facebook: locationData?.funnel_links?.social_facebook ?? "",
    instagram: locationData?.funnel_links?.social_instagram ?? "",
    youtube: locationData?.funnel_links?.social_youtube ?? "",
    tiktok: locationData?.funnel_links?.social_tiktok ?? "",
    x:
      locationData?.funnel_links?.social_x ??
      locationData?.funnel_links?.social_twitter ??
      "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const email = locationData?.owner?.email;

  const artistNameRef = useRef(null);

  const ALL_GENRES = [
    "Pop",
    "Rock",
    "Alternative",
    "Indie",
    "Metal",
    "Country",
    "Folk",
    "Americana",
    "Reggae",
    "Hip-Hop",
    "Rap",
    "R&B",
    "Jazz",
    "Classical",
    "Electronic",
    "Dance",
    "Christian",
    "Latin",
    "Comedy",
  ];

  const generateBioFromAI = async () => {
    const prompt = `
    Generate a short 1-paragraph bio for ${artistName} - a ${primaryGenre}/${subGenres} artist who:
    - Wants to convey ${questionAnswers.q1} through their music.
    - Has achieved ${questionAnswers.q2}.
    - Can be described as ${questionAnswers.q3}.
    It should be written in the third person by a journalist. Please make it shorter than 350 character count length.
  `;

    try {
      setIsSubmitting(true);
      const response = await axios.post(`${API_BASE_URL}/ai`, {
        message: prompt,
      });
      if (response.data) {
        setBio(response.data); // Assume the bio comes back in 'response.data.bio'
        setGenerateBio(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error generating bio:", error);
      setIsSubmitting(false);
    }
  };

  const completeArtistProfileOnboarding = async () => {
    if (!email || !ST_CRM_LOCATION_ID) {
      toast.error(
        "Missing email or location ID (reach out to support@modernmusician.me)"
      );
      return;
    }



    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
    const fields = {
      artistName,
      artistGenrePrimary: primaryGenre,
      artistSubgenres: subGenres,
      artistBioShort: bio,
      socialFacebookLink: socials.facebook,
      socialInstagramLink: socials.instagram,
      socialYoutubeLink: socials.youtube,
      socialTiktokLink: socials.tiktok,
      socialXLink: socials.x,
      onboardingStep1: formattedDate,
    };

    let formData = {
      location: { id: ST_CRM_LOCATION_ID },
      contact: { email },
      fields,
    };

    // if (locationData?.owner?.first_name) {
    //   data['first_name'] = locationData?.owner?.first_name;
    // }

    // if (locationData?.owner?.last_name) {
    //   data["last_name"] = locationData?.owner?.first_name;
    // }

    try {
      setIsSubmitting(true);

      const response = await axios.post(
        `${API_BASE_URL}/form/submit`,
        formData
      );
      console.log(response.data);

      if (response.data.response === "success") {
        // Replace with your actual success condition
        await trackCustomAction(
          MM_CRM_LOCATION_ID,
          email,
          "ALC Step 1 Completed"
        );
        // window.location.href = `https://launch.streetteam.me/log-in-redirect?crm_location_id=${crm_location_id}&email=${email}`;
        setCompleted("profile", fields);
        toast.success("Completed successfully");
      } else {
        // Handle unsuccessful form submission if needed
        toast.error(
          "Something went wrong. Please try again or reach out to support@modernmusician.me."
        );
      }
      
      // setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error submitting form", error);
      toast.error(
        `An error occurred while submitting the form. Please try again later or reach out to support@modernmusician.me. Error is ${error}`
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await completeArtistProfileOnboarding();
    console.log('refreshing data');
    await refreshData();
  };

  useEffect(() => {
    if (artistNameRef.current) {
      artistNameRef.current.focus();
    }
  }, []);

  // let headerCard = {
  //   icon: faUserCog,
  //   title: "Getting Started",
  //   description: "Set up your StreetTeam account in 2 minutes.",
  //   actionText: "Get Started",
  //   actionValue: "onboarding",
  //   col: 12,
  // };

  return (
    <div>
      <Container
        className="d-flex flex-column align-items-center mt-4"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #e0e0e0",
          padding: "40px",
          borderRadius: "10px",
        }}
      >
        {/* <h2 className="mb-4"> Enter Your Artist Details </h2> */}
        <Form onSubmit={handleSubmit}>
          <input type="hidden" name="locationId" value={ST_CRM_LOCATION_ID} />
          <input type="hidden" name="email" value={email} />
          <Form.Group controlId="artistName" className="py-2">
            <Form.Label>Artist Name</Form.Label>
            <Form.Control
              ref={artistNameRef}
              type="text"
              placeholder="Enter Artist Name"
              value={artistName}
              onChange={(e) => setArtistName(e.target.value)}
            />
          </Form.Group>

          {artistName && (
            <Form.Group controlId="primaryGenre" className="py-2">
              <Form.Label>Primary Genre</Form.Label>
              <Form.Text className="text-muted ms-2">
                (Choose one only)
              </Form.Text>
              <Form.Control
                as="select"
                value={primaryGenre}
                onChange={(e) => setPrimaryGenre(e.target.value)}
              >
                <option>Select a genre</option>
                {ALL_GENRES.map((genre, index) => (
                  <option key={index}>{genre}</option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {primaryGenre && (
            <Form.Group controlId="subGenres" className="py-2">
              <Form.Label>Subgenres</Form.Label>
              <Form.Text className="text-muted ms-2">
                (Choose up to three)
              </Form.Text>
              <div className="d-flex flex-wrap justify-content-between">
                {Array.from({ length: 6 }, (_, i) => i).map((colIndex) => (
                  <div className="subgenre-column me-2" key={colIndex}>
                    {Array.from({ length: 3 }, (_, i) => i + colIndex * 3)
                      .map((rowIndex) => {
                        const filteredGenres = ALL_GENRES.filter(
                          (genre) => genre !== primaryGenre
                        );
                        return filteredGenres[rowIndex];
                      })
                      .filter(Boolean) // Remove any undefined values
                      .map((genre, index) => (
                        <Form.Check
                          // custom
                          label={genre}
                          type="checkbox"
                          id={`custom-inline-checkbox-${index}`}
                          key={index}
                          disabled={
                            subGenres.length >= 3 && !subGenres.includes(genre)
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSubGenres([...subGenres, genre]);
                            } else {
                              setSubGenres(
                                subGenres.filter((g) => g !== genre)
                              );
                            }
                          }}
                          checked={subGenres.includes(genre)}
                        />
                      ))}
                  </div>
                ))}
              </div>
            </Form.Group>
          )}
          {subGenres.length > 0 && (
            <Form.Group controlId="bio" className="py-2">
              <Form.Label>Short Bio</Form.Label>
              <Form.Text
                onClick={() => setGenerateBio(!generateBio)}
                className="ms-2"
              >
                {!generateBio && (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => setGenerateBio(!generateBio)}
                  >
                    Click here to generate with ArtistAI
                  </span>
                )}
              </Form.Text>
              {!generateBio && (
                <>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    maxLength="400"
                    placeholder="Enter Bio (Max 400 characters)"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    className="mb-1"
                  />
                  <Form.Text>{bio.length}/400</Form.Text>
                </>
              )}

              {generateBio && (
                <div>
                  <Form.Label className="mt-2">
                    Answer these questions to generate your short bio using
                    ArtistAI:
                  </Form.Label>

                  {[
                    {
                      question:
                        "How would you describe your unique sound or style?",
                      key: "q1",
                    },
                    {
                      question:
                        "What are one or two key achievements or milestones in your career that you're proud of? (Think awards, sold-out shows, collaborations, etc.)",
                      key: "q2",
                      rows: 2,
                    },
                    {
                      question:
                        "What's one word (or a few) that best describes your musical vibe?",
                      key: "q3",
                    },
                  ].map(({ question, key, rows = 1 }) => (
                    <Form.Control
                      key={key}
                      className="mt-1"
                      as="textarea"
                      rows={rows}
                      placeholder={question}
                      onChange={(e) =>
                        setQuestionAnswers({
                          ...questionAnswers,
                          [key]: e.target.value,
                        })
                      }
                    />
                  ))}
                  <Button
                    variant="primary"
                    className="mt-2 bg-gradient"
                    onClick={generateBioFromAI}
                  >
                    {isSubmitting ? "Submitting..." : "Generate Bio"}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className="ms-2 mt-2 bg-gradient"
                    onClick={() => setGenerateBio(false)}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Form.Group>
          )}

          {bio && ( // Only show if Bio is filled
            <Form.Group className="py-2">
              <Form.Label>Artist Social Media Links</Form.Label>
              <Form.Text className="text-muted ms-2">(Optional)</Form.Text>
              <Row>
                <Col>
                  <Form.Control
                    placeholder="Enter Facebook Link"
                    onChange={(e) =>
                      setSocials({ ...socials, facebook: e.target.value })
                    }
                    value={socials.facebook}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Enter Instagram Link"
                    onChange={(e) =>
                      setSocials({ ...socials, instagram: e.target.value })
                    }
                    value={socials.instagram}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Enter YouTube Link"
                    onChange={(e) =>
                      setSocials({ ...socials, youtube: e.target.value })
                    }
                    value={socials.youtube}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Enter TikTok Link"
                    onChange={(e) =>
                      setSocials({ ...socials, tiktok: e.target.value })
                    }
                    value={socials.tiktok}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Enter 𝕏 Link"
                    onChange={(e) =>
                      setSocials({ ...socials, x: e.target.value })
                    }
                    value={socials.x}
                  />
                </Col>
              </Row>
            </Form.Group>
          )}
          {artistName && primaryGenre && subGenres.length > 0 && bio && (
            <Button
              variant="primary"
              type="submit"
              className="py-2 bg-gradient"
            >
              {isSubmitting && ( // Show spinner if submitting form
                <>
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="me-2"
                  ></Spinner>
                </>
              )}
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default ArtistProfile;
