import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Spinner,
} from "react-bootstrap";
import { uploadFile } from "../../../utils/uploadFile";
import { useSearchParams } from "react-router-dom";
import { postContentData } from "../utils";
import SkewedCubeImage from "./SkewedCubeImage";
import LocationContext from "../../../context/Locations/LocationContext";
import ContentPlayer from "../../streamContent/ContentPlayer";
import ErrorMessage from "../../../components/ErrorMessage";
const { v4: uuidv4 } = require("uuid");

export default function UploadRelic({ setCompleted, relicSubmissionType }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const {locationData} = useContext(LocationContext);
  const [formData, setFormData] = useState({});
  const [audioFile, setAudioFile] = useState(null);
  const [artworkFile, setArtworkFile] = useState(null);
  const [relicArtworkFile, setRelicArtworkFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(null);
  const [postedData, setPostedData] = useState({});
  const artworkFileRef = useRef(null);
  const audioFileRef = useRef(null);

  //steps of the form
  const steps = useMemo(
    () => [
      {
        action: "upload",
        title: "Upload Moment",
        description: "Upload a <30 second clip of your song",
        name: "Create Moment Relic™",
        type: "moment",
        rarity: "bronze",
        index: 0,
      },
      {
        action: "upload",
        title: "Upload Song",
        description: "Upload a full song",
        name: "Create Song Relic™",
        type: "song",
        rarity: "silver",
        index: 1,
      },
      {
        action: "upload",
        title: "Upload Album",
        description: "Upload a full album",
        name: "Create Album Relic™",
        type: "album",
        rarity: "gold",
        index: 2,
      },
      { action: "confirm", name: "Confirm", index: 3 },
    ],
    []
  );
  const [step, setStep] = useState(steps[0]);

  const [searchParams] = useSearchParams();

  // Define a helper function to determine what text should be displayed on the button
  const getConfirmationText = (postedData, momentId, songId, albumId) => {
    console.log(`postedData`, postedData);
    console.log(`getConfirmationText`, momentId, songId, albumId);
    if (momentId && songId && albumId) {
      return null;
    }
    if (momentId && songId && !albumId) {
      return "(Skip Album)";
    }
    if (momentId && !songId && !albumId) {
      return "(Skip Song/Album)";
    }
    // ...other combinations
    return null; // default case
  };

  const setFormDefault = useCallback(() => {
    //set default values
    const contentId = uuidv4().replace(/-/g, "").substring(0, 25);
    const audioId = `audio_${contentId}`;
    const imageId = `image_${contentId}`;
    const relicId = `relic_${contentId}`;
    const locationId = locationData?.id;
    const creatorId=`creator_${locationId}`;
    const artistName = locationData?.name || searchParams.get("artist_name");
    const email = locationData?.owner?.email;
    const title = searchParams.get("title") || "";
    const relicType = step.type;
    const relicRarity = searchParams.get("relicRarity") || step.rarity;
    setArtworkFile(null);
    setRelicArtworkFile(null);
    if (artworkFileRef.current) {
      artworkFileRef.current.value = "";
    }
    if (audioFileRef.current) {
      audioFileRef.current.value = "";
    }
    setAudioFile(null);
    setUploadPercentage(0);
    setFormData((prevData) => ({
      audioContent: {
        id: audioId,
        creator: {
          id: creatorId,
          name: artistName,
          email: email,
        },
        title: title,
        type: "audio",
      },
      imageContent: {
        id: imageId,
        creator: {
          id: creatorId,
          name: artistName,
          email: email,
        },
        title: title,
        type: "image",
      },
      relic: {
        id: relicId,
        locationId: locationId,
        title: title,
        category: "music",
        type: relicType,
        rarity: relicRarity,
        creator: {
          id: creatorId,
          name: artistName,
          email: email,
        },
        audioId: audioId,
        imageId: imageId, // might want to wait to set this until we confirm the content is created?
      },
    }));
  }, [
    setFormData,
    searchParams,
    locationData?.id,
    locationData?.name,
    locationData?.owner?.email,
    step,
  ]);

  useEffect(() => {
    setFormDefault();
  }, [setFormDefault]);

  const handleFileChange = (e, type) => {
    if (type === "audio") setAudioFile(e.target.files[0]);
    else if (type === "artwork") setArtworkFile(e.target.files[0]);
  };

  const handleStepAction = async () => {
    console.log(
      `at time of invalidFormCheck`,
      formData,
      audioFile,
      artworkFile
    );
    if (step.action === "upload") {
      // Check for empty fields
      if (
        !formData?.relic?.title ||
        !formData?.relic?.creator.name ||
        !audioFile ||
        !artworkFile
      ) {
        setErrorMessage("Please fill in all fields.");
        return;
      }
      await handleUpload(step.type);
    }
    if (step.index < steps.length - 1) {
      const nextStepIndex = step.index + 1;
      console.log(`setting step to`, nextStepIndex);
      setStep(steps[nextStepIndex]);
    }
  };

  const getRarityFromType = (relicType) => {
    let relicRarity = "bronze";
    if (relicType === "song") {
      relicRarity = "silver";
    } else if (relicType === "album") {
      relicRarity = "gold";
    }
    return relicRarity;
  };

  const handleUpload = useCallback(
    async (type) => {
      try {
        setIsUploading(true);
        let uploadAudioResponse,
          // uploadArtworkResponse,
          uploadRelicArtworkResponse;
        if (audioFile) {
          console.log(`audioFile is`, audioFile);
          const name = audioFile?.name;
          const ext = name.split(".")[name.split(".").length - 1];
          const fileName = `${formData.audioContent.id}.${ext}`;
          uploadAudioResponse = await uploadFile(
            audioFile,
            formData.audioContent.creator.id,
            fileName,
            function (progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded / progressEvent.total / 3) * 100
              );
              setUploadPercentage(percentCompleted);
            }
          );
          console.log(`uploadAudioResponse`, uploadAudioResponse);
        }
        //we could upload an artwork file, but we don't need to because we're just uploading the relic artwork 
        // if (artworkFile) {
        //   const name = artworkFile?.name;
        //   const ext = name.split(".")[name.split(".").length - 1];
        //   const fileName = `${formData.imageContent.id}.${ext}`;
        //   uploadArtworkResponse = await uploadFile(
        //     artworkFile,
        //     formData.imageContent.creator.id,
        //     fileName,
        //     function (progressEvent) {
        //       const percentCompleted = Math.round(
        //         33 + (progressEvent.loaded / progressEvent.total / 3) * 100
        //       );
        //       setUploadPercentage(percentCompleted);
        //     }
        //   );
        //   console.log(`artwork uploadResponse`, uploadArtworkResponse);
        // }
        if (relicArtworkFile) {
          const name = relicArtworkFile?.name;
          const ext = name.split(".")[name.split(".").length - 1];
          const fileName = `${formData.relic.id}.${ext}`;
          uploadRelicArtworkResponse = await uploadFile(
            relicArtworkFile,
            formData.imageContent.creator.id,
            fileName,
            function (progressEvent) {
              const percentCompleted = Math.round(
                67 + (progressEvent.loaded / progressEvent.total / 3) * 100
              );
              setUploadPercentage(percentCompleted);
            }
          );
          console.log(`relicArtworkFile uploadResponse`, relicArtworkFile);
        }
        setFormData((prevData) => ({
          ...prevData,
          audioContent: {
            ...prevData.audioContent,
            url: uploadAudioResponse?.publicUrl,
          },
          imageContent: {
            ...prevData.imageContent,
            url: uploadRelicArtworkResponse?.publicUrl,
          }
        }));
        if (
          uploadAudioResponse?.publicUrl &&
          // uploadArtworkResponse?.publicUrl &&
          uploadRelicArtworkResponse?.publicUrl
        ) {
          console.log('posting data to postContentData');
          const dataResponse = await postContentData({
            ...formData,
            audioContent: {
              ...formData.audioContent,
              url: uploadAudioResponse?.publicUrl,
            },
            imageContent: {
              ...formData.imageContent,
              url: uploadRelicArtworkResponse?.publicUrl,
            }
          });
          console.log(`dataResponse is`, dataResponse);
          setPostedData((prev) => ({ ...prev, [step.type]: dataResponse }));
          setFormDefault();
        } else {
          setErrorMessage("Something went wrong, please try again");
        }
        setIsUploading(false);
      } catch (error) {
        console.error("Error:", error);
        setIsUploading(false);
      }
    },
    [
      // artworkFile,
      audioFile,
      relicArtworkFile,
      formData,
      setFormDefault,
      setPostedData,
      step.type,
    ]
  );

  return (
    <Container
      // className="shadow rounded"
      style={{
        // backgroundColor: "#fff",
        // border: "1px solid #e0e0e0",
        // borderRadius: "10px",
        padding: "20px",
        maxWidth: "500px",
      }}
    >
      {/* <Card className="px-3 py-2 d-grid gap-2"> */}
      {/* <h2 className="text-center">Create Your Music Relics</h2> */}
      {step.index < steps.length - 1 && (
        <>
          <h4 className="text-center">{step.title}</h4>
          <p className="text-center mb-4">{step.description}</p>
          <Form className="mt-2 d-grid gap-3">
            <Form.Group as={Row} controlId="formTitle">
              <Form.Label column sm="2">
                Title
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  value={formData?.relic?.title || ""}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      relic: { ...prevData.relic, title: e.target.value },
                    }))
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formArtist">
              <Form.Label column sm="2">
                Artist
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  required
                  type="text"
                  placeholder="Artist"
                  value={formData?.relic?.creator?.name || ""}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      relic: {
                        ...prevData.relic,
                        creator: {
                          ...prevData.relic.creator,
                          'name':e.target.value
                        }
                      },
                      audioContent: {
                        ...prevData.audioContent,
                        creator: {
                          ...prevData.audioContent.creator,
                          'name':e.target.value
                        }
                      },
                      imageContent: {
                        ...prevData.imageContent,
                        creator: {
                          ...prevData.audioContent.creator,
                          'name':e.target.value
                        }
                      }
                    }))
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="relicType">
              <Form.Label column sm="2">
                Type
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  value={
                    relicSubmissionType ??
                    formData?.relic?.type ??
                    "moment"
                  }
                  disabled={true}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      relic: {
                        ...prevData.relic,
                        type: e.target.value,
                        rarity: getRarityFromType(e.target.value),
                      },
                    }))
                  }
                >
                  <option value="moment">Moment</option>
                  <option value="song">Song</option>
                  <option value="album">Album</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formArtwork">
              <Form.Label column sm="2">
                Artwork
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  required
                  type="file"
                  accept="image/*"
                  ref={artworkFileRef}
                  onChange={(e) => {
                    handleFileChange(e, "artwork");
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formAudio">
              <Form.Label column sm="2">
                Audio
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  required
                  type="file"
                  accept=".mp3, audio/wav, audio/x-aiff, audio/x-m4a"
                  ref={audioFileRef}
                  onChange={(e) => {
                    handleFileChange(e, "audio");
                  }}
                />
              </Col>
            </Form.Group>

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            {isUploading && (
              <Button
                variant="secondary"
                className="bg-gradient"
                onClick={handleStepAction}
                disabled={isUploading}
              >
                <Spinner />
              </Button>
            )}
            {!isUploading && !postedData[step.name] && (
              <Button
                variant="secondary"
                className="bg-gradient"
                onClick={handleStepAction}
              >
                {step.name}
              </Button>
            )}
            {uploadPercentage > 0 && uploadPercentage < 100 && (
              // <p className="text-center">Uploading...</p>
              <ProgressBar
                now={uploadPercentage}
                label={`${uploadPercentage}%`}
                variant={uploadPercentage === 100 ? "success" : "loading"} //for this custom css to work, we need to create classes for it
              />
            )}
          </Form>
        </>
      )}
      {/* </Card> */}
      {artworkFile && !postedData[step.type] && (
        <Card className="d-flex shadow p-3 my-3 border-0 align-items-center">
          <SkewedCubeImage
            imageFile={artworkFile}
            contentId={formData?.relic.id}
            relicRarity={formData?.relic.rarity}
            setRelicArtworkFile={setRelicArtworkFile}
          />
        </Card>
      )}
      {step.index > 0 && (
        <Card className="d-flex shadow p-3 my-3 border-0 align-items-center gap-2 p-4">
          <h4> Preview Your Relics: </h4>
          {Object.entries(postedData).map(([key, value]) => {
            // console.log(`key:${key},value`,value);
            let relic = value?.data?.data?.relic.items[0].value
            relic['audio'] = value?.data?.data?.audio.items[0].value
            relic['image'] = value?.data?.data?.image.items[0].value
            console.log('relic is',relic)
            return (
            <ContentPlayer
              key={key}
              contentType="relic"
              preview={true}
              contentData={relic}
            />)
          })}
          <Button
            className="w-100 bg-gradient"
            onClick={() =>
              setCompleted(
                postedData["moment"]?.data?.data?.relic.items[0].value.id,
                postedData["song"]?.data?.data?.relic?.items[0].value.id,
                postedData["album"]?.data?.data?.relic?.items[0].value.id,
              )
            }
          >
            {" "}
            Confirm All
            <div className="fs-8 text-muted">
              {getConfirmationText(
                postedData,
                postedData["moment"]?.data?.data?.relic.items[0].value.id,
                postedData["song"]?.data?.data?.relic?.items[0].value.id,
                postedData["album"]?.data?.data?.relic?.items[0].value.id,
              ) ?? ""}
            </div>
          </Button>
        </Card>
      )}
    </Container>
  );
}
