import React, { useContext, useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import Header from "./components/Header";
import Roadmap from "./components/Roadmap";
import RequestSidebar from "./components/RequestSidebar";
import NewRequestModal from "./components/NewRequestModal";
import { DragDropContext } from "react-beautiful-dnd";
import UpvoteModal from "./components/UpvoteModal";
import axios from "axios";
import ContactContext from "../../context/Contacts/ContactContext";
import { getLocationIdFromPath } from "../../context/Contacts/ContactProvider";
import { API_BASE_URL } from "../../utils/constants";

const SoundBoard = () => {
  const [contactData] = useContext(ContactContext);

  const [requests, setRequests] = useState({
    Requested: [],
    "In Progress": [],
    Completed: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  // const [tokenAmount, setTokenAmount] = useState(10);
  const [upvoteModalShow, setUpvoteModalShow] = useState(false);
  const [tuneTokenIdToUpvote, setTuneTokenIdToUpvote] = useState(null);
  const [user, setUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [showInsufficientTokensModal, setShowInsufficientTokensModal] =
    useState(false);
  const [customPageValues, setCustomPageValues] = useState({});

  // Extract locationId and soundboardId from the URL
  // const pathParams = window.location.pathname.split("/");
  // const locationId = pathParams[2];
  // const soundboardId = pathParams[3] ?? "default";

  // console.log("locationId", locationId);

  const locationId = getLocationIdFromPath(window.location.pathname);
  const soundboardId = "default";

  // const loggedInUser = MOCK_USERS[0];
  // const loggedInUser = null;

  // const isLoggedIn = loggedInUser ? true : false; // change this according to your authentication status
  // const userprofile_picture = ""; // user avatar url if logged in
  // const [isDragging, setIsDragging] = useState(false);

  let apiDomain = "https://cloud.streetteam.me";
  // apiDomain = "https://preview.streetteam.me";
  // apiDomain = process.env.REACT_APP_SANDBOX_URL;
  // const urlParams = new URLSearchParams(window.location.search);
  // const contactId = urlParams.get("contact_id");
  // const [loginLink, setLoginLink] = useState(null);
  const [loading, setLoading] = useState(false); // State to manage loading

  const handleUpvote = (tuneTokenId) => {
    setTuneTokenIdToUpvote(tuneTokenId);
    setUpvoteModalShow(true);
  };

  const performUpvote = (tokens, comment) => {
    console.log("performUpvote started", tokens, comment); // Log the start of the upvote process
    // Check if user has enough tokens
    if (tokens > user.tokens) {
      console.log("Not enough tokens, showing modal...");
      // Not enough tokens, so show modal
      setShowInsufficientTokensModal(true);
    } else {
      upvote(tuneTokenIdToUpvote, tokens, comment);
      setUpvoteModalShow(false); // Hide modal after upvote
    }
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("mm_id");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      // Check if the logged-in user is the admin
      setIsAdmin(parsedUser.email === customPageValues?.owner_email);
    }
  }, [customPageValues?.owner_email]);

  useEffect(() => {
    // Function to fetch user data by contactId
    const fetchUserData = async (contactId) => {
      try {
        setLoading(true); // Start loading

        const response = await axios.get(
          `${apiDomain}/${locationId}/contacts/${contactId}`
        );
        const contact = response.data.contact;
        console.log("retrieved contact", contact);
        const userData = {
          id: contact.id,
          name: contact.full_name,
          profile_picture: contact.profile_picture?.[0],
          email: contact.email,
          tokens: contact.tokens,
        };
        setUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));
        setIsAdmin(userData.email === customPageValues?.owner_email);
      } catch (error) {
        console.error("Error fetching user data:", error);
        // Handle error, e.g., setting an error state, showing a notification, etc.
      } finally {
        setLoading(false); // Stop loading regardless of the outcome
      }
    };

    // // Get the contactId from the URL
    // const urlParams = new URLSearchParams(window.location.search);
    // const contactId = urlParams.get("crm_id");

    // Call fetchUserData if contactId is available
    if (contactData.id) {
      fetchUserData(contactData.id);
    }
  }, [apiDomain, locationId, contactData.id, customPageValues?.owner_email]); // Empty dependency array ensures this effect only runs on mount and unmount

  // const handleTokenChange = (e) => {
  //   // You can include validation logic here to ensure it's a number, within a certain range, etc.
  //   setTokenAmount(Math.max(0, e.target.value)); // Replace 0 with minimum tokens if there is one
  // };

  // const handleUpvote = (requestId) => {
  //   const tokens = parseInt(tokenAmount);
  //   if (!isNaN(tokens) && tokens > 0) {
  //     upvote(requestId, tokens);
  //     setTokenAmount(1); // Reset the token amount after upvoting
  //   } else {
  //     alert("Please enter a valid number of tokens"); // You might want to use a more user-friendly notification system
  //   }
  // };

  // useEffect(() => {
  //   // Initialize __NUXT__ object and nested structures if they don't exist
  //   window.__NUXT__ = window.__NUXT__ || {};
  //   window.__NUXT__.data = window.__NUXT__.data || {};
  //   window.__NUXT__.data.pageData = window.__NUXT__.data.pageData || {};

  //   // Set the locationId
  //   window.__NUXT__.data.pageData.locationId = locationId;
  // }, [locationId]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/${locationId}/pageValues`)
      .then((response) => response.json())
      .then((data) => {
        setCustomPageValues(data);
      })
      .catch((error) => {
        console.error("Error fetching login link:", error);
      });
  }, [locationId]);

  // if customPageValues?.owner_id === contactData?.id then set admin
  useEffect(() => {
    if (customPageValues?.owner_email === contactData?.email) {
      setIsAdmin(true);
      // make sure admin has 100,000 tokens
      if (user?.tokens < 100000) {
        setUser((currentUser) => {
          const updatedTokens = 100000;
          const updatedUser = { ...currentUser, tokens: updatedTokens };
          localStorage.setItem("user", JSON.stringify(updatedUser));
          return updatedUser;
        });
      }
    }
  }, [customPageValues, contactData, user?.tokens]);

  // useEffect(() => {
  //   if (loginLink && !contactId) {
  //     const redirectUrl = `${loginLink}?redirect_url=${window.location.origin}${window.location.pathname}`;
  //     window.location.href = redirectUrl;
  //   }
  // }, [loginLink, locationId, contactId]);

  useEffect(() => {
    // Initialize state with empty columns
    let initialRequests = {
      Requested: [],
      "In Progress": [],
      Completed: [],
    };

    // Function to fetch soundboard data
    const fetchSoundboard = async () => {
      try {
        console.log("Fetching soundboard..."); // Log the start of the soundboard fetch process
        // Replace `apiDomain` with the actual domain of your API
        // Assume 'locationId' is available in this component's context or props
        // const locationId = "40w74OIRtBJaXkcwkVNQ"; // Replace with actual locationId
        // const soundboardId = "default";
        const response = await axios.get(
          `${apiDomain}/soundboards/${locationId}/${soundboardId}`
        );
        const soundboardData = response.data.soundboard;

        if (soundboardData) {
          // Assuming you want to push the contents of each status into the initialRequests object
          Object.entries(soundboardData.requests).forEach(
            ([status, requestsArray]) => {
              if (initialRequests[status]) {
                initialRequests[status].push(...requestsArray);
              } else {
                initialRequests[status] = requestsArray;
              }
            }
          );
        } else {
          // Create a new soundboard if data is not returned
          await createNewSoundboard();
        }

        setRequests(initialRequests);
      } catch (error) {
        console.error("Error fetching soundboard:", error);
        // Here, set some error state or handle the error as needed
      }
    };
    // Function to create a new soundboard
    const createNewSoundboard = async () => {
      console.log("Creating new soundboard..."); // Log the start of the soundboard creation process
      try {
        const newSoundboardData = {
          requests: initialRequests,
        };
        await axios.post(
          `${apiDomain}/soundboards/${locationId}/${soundboardId}`,
          newSoundboardData
        );
        // Handle successful creation
      } catch (error) {
        console.error("Error creating new soundboard:", error);
      }
    };

    // Call the function to fetch soundboard data
    fetchSoundboard();
  }, [apiDomain, locationId, soundboardId]);

  useEffect(() => {
    console.log(`user changed:`, user); // Log the user object when it changes
  }, [user]);

  const handleLogin = async (e, email) => {
    e.preventDefault();

    console.log("handleLogin started", email); // Log the start of the login process
    // use axios to get contact from the /location/contact endpoint by email, then set user based on response - id, name, profile_picture, email, tokens
    try {
      setLoading(true); // Start loading

      const response = await axios.get(
        `${apiDomain}/${locationId}/contact?email=${email}`
      );
      const contact = response.data.contact;
      console.log("retrieved contact", contact);
      const userData = {
        id: contact.id,
        name: contact.full_name,
        profile_picture: contact.profile_picture?.[0],
        email: contact.email,
        tokens: contact.tokens,
      };
      setUser(userData);
      localStorage.setItem("user", JSON.stringify(userData));
      setIsAdmin(userData.email === customPageValues?.owner_email);
    } catch (error) {
      console.error("Login error:", error);
      // handle error, maybe set an error message in state to display to the user
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };
  const handleLogout = () => {
    // Clear user state
    localStorage.removeItem("mm_id");
    setIsAdmin(false);
    setUser({});

    // Remove user data from local storage

    // Redirect to home page or login page after logout
    // This is a simple example, you might have a different path or method for redirecting
    // window.location.href = "/";
  };

  const handleNewRequest = async ({ title, details, tokens }) => {
    // Check if user has enough tokens
    if (tokens > user.tokens) {
      // Not enough tokens, so show modal
      setShowInsufficientTokensModal(true);
    } else {
      const newRequest = {
        id: Date.now(),
        status: "Requested",
        title,
        description: details,
        tokens: parseInt(tokens),
        poster: {
          id: user.id ?? `anon_${Date.now()}`,
          name: user.name ?? "Anonymous",
          profile_picture: user.profile_picture ?? "Your Avatar URL",
          datetime: new Date().toISOString(),
          tokens: parseInt(tokens),
        },
        contributors: [],
      };

      let updatedRequests = { ...requests };
      updatedRequests["Requested"] = [
        ...updatedRequests["Requested"],
        newRequest,
      ];
      setRequests(updatedRequests);
      await updateSoundboardRequests("default", updatedRequests);
      await subtractUserTokens(user.id, tokens);
    }
  };
  const handleDragStart = () => {
    // setIsDragging(true);
  };
  const handleDragEnd = (result) => {
    console.log("handleDragEnd started", result); // Log the start of the drag end and the result object

    // setIsDragging(false);

    const { source, destination } = result;
    if (!destination) {
      console.log("No destination, returning..."); // Log if there's no destination
      return;
    }

    console.log("Destination found:", destination.droppableId); // Log the destination

    if (destination.droppableId === "abandon") {
      console.log("Trying to abandon request..."); // Log when trying to abandon a request
      if (window.confirm("Are you sure you want to abandon request?")) {
        setRequests((prev) => {
          const newRequests = { ...prev };
          newRequests[source.droppableId] = newRequests[
            source.droppableId
          ].filter((request, index) => index !== source.index);
          return newRequests;
        });
      }
      return;
    }

    const startStatus = source.droppableId;
    const endStatus = destination.droppableId;

    console.log("Moving request from", startStatus, "to", endStatus); // Log the status change

    // Find the request and update its status
    setRequests((prev) => {
      const newRequests = { ...prev };

      const startStatus = source.droppableId;
      const endStatus = destination.droppableId;

      // Check #1: Ensure startStatus exists in newRequests and is an array
      if (
        !newRequests[startStatus] ||
        !Array.isArray(newRequests[startStatus])
      ) {
        console.error("Unexpected source structure:", newRequests[startStatus]);
        return prev; // Return the previous state without modification
      }

      // Remove the request from the source
      console.log("Before:", JSON.stringify(newRequests));
      const [removed] = newRequests[startStatus].splice(source.index, 1);
      console.log("Removed from source:", removed);

      // Check #2: Ensure the splice operation returned a valid request
      if (!removed) {
        console.error("Failed to remove request from source:", source);
        return prev; // Return the previous state without modification
      }

      // Modify the status of the removed request
      removed.status = endStatus;

      // Check #3: Ensure endStatus exists in newRequests and is an array
      if (!newRequests[endStatus] || !Array.isArray(newRequests[endStatus])) {
        console.error(
          "Unexpected destination structure:",
          newRequests[endStatus]
        );
        return prev; // Return the previous state without modification
      }

      // Add the removed request to its destination
      console.log("After", JSON.stringify(newRequests));
      newRequests[endStatus].splice(destination.index, 0, removed);
      console.log("Added to destination:", removed);
      return newRequests;
    });
    updateSoundboardRequests("default", requests);

    console.log("handleDragEnd completed"); // Log the end of the drag end process
  };

  const handleRefund = async (request) => {
    try {
      let refunds = [];
      refunds.push({
        id: request.poster.id, // Ensure that 'poster' has an 'id' field
        amount: request.poster.tokens,
      });
      request.contributors.forEach((contributor) => {
        refunds.push({
          id: contributor.id, // Ensure that 'contributors' have an 'id' field
          amount: contributor.tokens,
        });
      });
      await axios.post(`${apiDomain}/token-refunds/${locationId}`, refunds);

      let updatedRequests = { ...requests };
      updatedRequests[request.status] = updatedRequests[request.status].filter(
        (r) => r.id !== request.id
      );
      setRequests(updatedRequests);
      await updateSoundboardRequests("default", updatedRequests);

      await setUser((currentUser) => {
        const updatedTokens = currentUser.tokens + request.tokens;
        const updatedUser = { ...currentUser, tokens: updatedTokens };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        return updatedUser;
      });
      // close modal
      setShowOffcanvas(false);
    } catch (error) {
      console.error("Error refunding tokens:", error);
      // You may want to handle this more gracefully in a production environment
    }
  };

  // Subtract user tokens and update the database
  const subtractUserTokens = async (userId, tokensToSubtract) => {
    // Update user state and local storage immediately for a responsive UI
    setUser((currentUser) => {
      const updatedTokens = currentUser.tokens - tokensToSubtract;
      const updatedUser = { ...currentUser, tokens: updatedTokens };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return updatedUser;
    });

    // Now update the backend
    try {
      await axios.post(`${apiDomain}/contacts/${locationId}/${userId}`, {
        tokens: user.tokens - tokensToSubtract,
      });
    } catch (error) {
      console.error("Error subtracting tokens from user:", error);
      // You may want to handle this more gracefully in a production environment
    }
  };

  // Update soundboard data on the backend
  const updateSoundboardRequests = async (soundboardId, updatedRequests) => {
    try {
      soundboardId = "default";
      await axios.post(
        `${apiDomain}/soundboards/${locationId}/${soundboardId}`,
        {
          requests: updatedRequests,
        }
      );
    } catch (error) {
      console.error("Error updating soundboard data:", error);
      // Handle error
    }
  };

  const upvote = async (tuneTokenId, tokens, comment) => {
    setRequests((prev) => {
      const newRequests = { ...prev };
      for (const status in newRequests) {
        newRequests[status] = newRequests[status].map((request) => {
          if (request.id === tuneTokenId) {
            request.tokens += tokens;
            request.contributors.push({
              id: user.id ?? `anon_${Date.now()}`,
              name: user.name ?? "Anonymous",
              tokens: tokens,
              profile_picture: user.profile_picture ?? "Your Avatar URL",
              comment: comment ?? `Added ${tokens} tokens!`,
              datetime: new Date().toISOString(),
            });
          }
          return request;
        });
      }
      return newRequests;
    });

    await updateSoundboardRequests("default", requests);

    // Call this function within your upvote function after updating the request
    await subtractUserTokens(user.id, tokens);
  };

  return (
    <Container className="bg-light vh-100 p-5" fluid>
      <Header
        onShowModal={() => setShowModal(true)}
        user={user}
        handleLogin={handleLogin}
        onLogout={handleLogout}
        loading={loading} // Pass the loading state to the Header component
        pageValues={customPageValues}
      />
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Roadmap
          requests={requests}
          upvote={upvote}
          onSelect={(request) => {
            setSelectedRequest(request);
            setShowOffcanvas(true);
          }}
          isAdmin={isAdmin} // Pass isAdmin to the Roadmap component
        />
      </DragDropContext>
      <RequestSidebar
        request={selectedRequest}
        show={showOffcanvas}
        onHide={() => {
          setShowOffcanvas(false);
          setSelectedRequest(null);
        }}
        handleUpvote={handleUpvote}
        isAdmin={isAdmin}
        handleRefund={handleRefund}
      />
      <UpvoteModal
        show={upvoteModalShow}
        onHide={() => setUpvoteModalShow(false)}
        onUpvote={performUpvote}
      />
      <NewRequestModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={handleNewRequest}
      />
      {showInsufficientTokensModal && (
        <Modal
          show={showInsufficientTokensModal}
          onHide={() => setShowInsufficientTokensModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Insufficient Tokens</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You do not have enough tokens to perform this action.</p>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              variant="primary"
              onClick={() =>
                (window.location.href =
                  "https://buy.stripe.com/dR6eY7eCi8bJ5Fu6oC")
              }
            >
              Reload Tokens
            </Button> */}
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default SoundBoard;
