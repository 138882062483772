import React, { useContext, useState } from "react";
import { Container, Form, Button, Card, Badge } from "react-bootstrap";
import LocationContext from "../../../../../context/Locations/LocationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../../../utils/constants";
import axios from "axios";
import { isValidSubdomain } from "../../../../../utils/validation";

import { toast } from "react-toastify";
import DomainSetupStep1 from "./DomainSetupStep1";
import DomainSetupStep2 from "./DomainSetupStep2";
import DomainSetupStep3 from "./DomainSetupStep3";
import {
  MM_CRM_LOCATION_ID,
  ST_CRM_LOCATION_ID,
} from "../../../../../integrations/Highlevel/constants";
import { trackCustomAction } from "../../../api";

export function Stepper({ currentStep }) {
  const steps = ["Select Domain", "Connect Domain", "Customize Links"];

  return (
    <div className="stepper mb-4 mt-3">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div
            className={`text-center step ${
              currentStep === index + 1 ? "active" : ""
            }`}
          >
            <Badge className="step-number bg-secondary text-white">
              {index + 1}
            </Badge>
            <div className="step-label text-black mt-1">{step}</div>
          </div>
          {index !== steps.length - 1 && <hr className="step-line" />}
        </React.Fragment>
      ))}
    </div>
  );
}

const Fans = ({ setCompleted }) => {
  const {locationData} = useContext(LocationContext);
  const [activeKey, setActiveKey] = useState("1"); // The default active key is 1

  let subdomainLowercase = locationData?.name?.toLowerCase();
  let subdomainNoSpaces = subdomainLowercase?.replace(/\s/g, "");

  const SETUP_DOMAIN_LINK = `https://app.streetteam.me/v2/location/${locationData.id}/settings/domain`;
  const ADD_DOMAIN_LINK = `https://app.streetteam.me/v2/location/${locationData.id}/funnels-websites/funnels`;

  const [domainOption, setDomainOption] = useState(""); // "subdomain" or "custom"
  const [subdomain, setSubdomain] = useState(subdomainNoSpaces || "");
  // const [customDomain, setCustomDomain] = useState("");
  const [customSubdomain, setCustomSubdomain] = useState("");
  const [customRoot, setCustomRoot] = useState("");
  const [copied, setCopied] = useState(false);

  const [domainValidationState, setDomainValidationState] = useState("idle"); // "idle", "in_progress", "validated", "error"

  const [funnelLinks, setFunnelLinks] = useState({
    streaming: { path: "/streaming", validationState: "idle" },
    community: { path: "/community", validationState: "idle" },
    starterPack: { path: "/starter-pack", validationState: "idle" },
    innerCircle: { path: "/inner-circle", validationState: "idle" },
    relicHub: { path: "/relic-hub", validationState: "idle" },
  });

  // const [hasAttemptedValidation, setHasAttemptedValidation] = useState(false);

  const [surveyStep, setSurveyStep] = useState(1);

  const [domain, setDomain] = useState("");

  // const [domainInput, setDomainInput] = useState("");
  const [isValid, setIsValid] = useState(null); // null for not yet validated, true for valid, false for invalid

  // const handleInputChange = (e) => {
  //   setDomainInput(e.target.value);
  // };

  const handleInputBlur = (inputDomainOption) => {
    if (inputDomainOption === "subdomain") {
      let subdomainTestUrl = `${subdomain}.streetteam.site`;

      const validation = isValidSubdomain(subdomainTestUrl);

      setIsValid(validation);
    } else if (domainOption === "subdomain") {
      let subdomainTestUrl = `${subdomain}.streetteam.site`;

      const validation = isValidSubdomain(subdomainTestUrl);

      setIsValid(validation);
    } else if (domainOption === "custom") {
      if (customSubdomain && customRoot) {
        const validation = isValidSubdomain(`${customSubdomain}.${customRoot}`);
        setIsValid(validation);
      }
    }
  };

  // const handleConnectYourDomainClick = (domain) => {
  //
  //   // toast.success("Copied domain to clipboard!");
  //   // setCopied(true);
  //   handleCopyText(domain);
  //   // setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  //   window.open(SETUP_DOMAIN_LINK, "_blank", "width=1280,height=720");
  // };

  const handleConnectDomainClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setActiveKey("3");
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      window.open(SETUP_DOMAIN_LINK, "_blank", "width=1280,height=720");
    } catch (error) {
      console.error("Error copying text: ", error);
    }
  };

  const handleDomainChoice = (choice) => {
    setIsValid(null);
    setDomainValidationState("idle");
    setDomainOption(choice);

    if (choice === "subdomain") {
      handleInputBlur(choice);
    }
  };

  const handleLinkChange = (path, linkName) => {
    setFunnelLinks((prevLinks) => ({
      ...prevLinks,
      [linkName]: {
        ...prevLinks[linkName],
        path: path,
      },
    }));
  };

  const handleDomainValidation = async () => {
    let url = `${API_BASE_URL}/validate-link?url=${domain}`;

    try {
      setDomainValidationState("in_progress");

      const data = await axios(url);

      if (data.status === 404) {
        setActiveKey("2");
        setDomainValidationState("error");
      } else {
        setActiveKey("4");
        setDomainValidationState("validated");
      }
    } catch (error) {
      // Handle error as you see fit
      console.error("Error validating domain:", error);
      setActiveKey("2");
      setDomainValidationState("error");
    }
  };

  const handleLinkValidation = async (key) => {
    let link;
    if (!funnelLinks[key] || !funnelLinks[key].path || !domain) {
      toast.error(
        "Error with handleLinkValidation. Please reach out to support@modernmusician.me"
      );
      return;
    } else {
      link = `${domain}${funnelLinks[key].path}`;
    }

    let url = `${API_BASE_URL}/validate-link?url=${link}`;
    //

    setFunnelLinks((prevLinks) => ({
      ...prevLinks,
      [key]: { ...prevLinks[key], validationState: "in_progress" },
    }));

    try {
      const data = await axios(url);

      if (data.status !== 200) {
        //
        setFunnelLinks((prevLinks) => ({
          ...prevLinks,
          [key]: { ...prevLinks[key], validationState: "error" },
        }));
      } else if (data.status === 200) {
        setFunnelLinks((prevLinks) => ({
          ...prevLinks,
          [key]: { ...prevLinks[key], validationState: "validated" },
        }));
      }
    } catch (error) {
      // Handle error as you see fit
      console.error("Error validating link:", error);
      //
      setFunnelLinks((prevLinks) => ({
        ...prevLinks,
        [key]: { ...prevLinks[key], validationState: "error" },
      }));
    }
  };

  const areAllLinksValid = () => {
    return Object.values(funnelLinks).every(
      (link) => link.validationState === "validated"
    );
  };
  const completeFanOnboarding = async () => {
    let email = locationData?.owner?.email;
    if (!email || !ST_CRM_LOCATION_ID) {
      toast.error(
        "Missing email or location ID (reach out to support@modernmusician.me)"
      );
      return;
    }

    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

    const fields = {
      onboardingStep3: formattedDate,
      fanJourneyLink: domain,
      streamingLink: domain + funnelLinks.streaming.path,
      communityLink: domain + funnelLinks.community.path,
      starterPackLink: domain + funnelLinks.starterPack.path,
      innerCircleLink: domain + funnelLinks.innerCircle.path,
      relicHubLink: domain + funnelLinks.relicHub.path,
    };

    let formData = {
      location: { id: ST_CRM_LOCATION_ID },
      contact: { email },
      fields,
    };

    try {
      console.log("Submitting fan form with data", formData);

      const response = await axios.post(
        `${API_BASE_URL}/form/submit`, // keeping the same endpoint as you mentioned
        formData
      );
      console.log(response.data);

      if (response.data.response === "success") {
        await trackCustomAction(
          MM_CRM_LOCATION_ID,
          email,
          "ALC Step 3 Completed" // Updated as per your specification
        );
        setCompleted("fans", fields);
        toast.success("Fan Journey completed successfully");
      } else {
        toast.error(
          "Something went wrong. Please try again or reach out to support@modernmusician.me."
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Something went wrong. Please try again or reach out to support@modernmusician.me"
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (areAllLinksValid()) {
      completeFanOnboarding();
    }
  };

  //
  //
  //
  //

  return (
    <Container
      className="my-4 d-flex justify-content-center"
      style={{
        backgroundColor: "#fff",
        border: "1px solid #e0e0e0",
        padding: "30px",
        borderRadius: "10px",
        maxWidth: "100vw",
      }}
    >
      <Card className="px-3 py-2" style={{ border: "none", minWidth: "35%" }}>
        <div className="mb-4">
          <h2 className="text-center">Create Your Fan Journey</h2>
          {/* <Stepper currentStep={surveyStep} /> */}
        </div>

        <Form onSubmit={handleSubmit}>
          {surveyStep === 1 && (
            <DomainSetupStep1
              surveyStep={surveyStep}
              domainOption={domainOption}
              handleDomainChoice={handleDomainChoice}
              subdomain={subdomain}
              setSubdomain={setSubdomain}
              handleInputBlur={handleInputBlur}
              customSubdomain={customSubdomain}
              setCustomSubdomain={setCustomSubdomain}
              customRoot={customRoot}
              setCustomRoot={setCustomRoot}
              isValid={isValid}
            />
          )}

          {surveyStep === 2 && (
            <DomainSetupStep2
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              handleConnectDomainClick={handleConnectDomainClick}
              domain={domain}
              copied={copied}
              ADD_DOMAIN_LINK={ADD_DOMAIN_LINK}
              handleDomainValidation={handleDomainValidation}
              domainValidationState={domainValidationState}
              funnelLinks={funnelLinks}
              handleLinkChange={handleLinkChange}
              handleLinkValidation={handleLinkValidation}
              domainOption={domainOption}
            />
          )}

          {surveyStep === 3 && (
            <DomainSetupStep3
              domain={domain}
              funnelLinks={funnelLinks}
              handleLinkChange={handleLinkChange}
              handleLinkValidation={handleLinkValidation}
              ADD_DOMAIN_LINK={ADD_DOMAIN_LINK}
            />
          )}

          <div className="text-center mt-3 d-flex justify-content-center align-items-center">
            {surveyStep > 1 && (
              <button
                type="button"
                className="custom-back-stepper me-2"
                onClick={() => setSurveyStep(surveyStep - 1)}
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back
              </button>
            )}

            {surveyStep === 1 &&
              (subdomain || (customSubdomain && customRoot)) &&
              isValid && (
                <button
                  type="button"
                  className="custom-next-stepper"
                  onClick={() => {
                    if (domainOption === "subdomain") {
                      setDomain(`https://${subdomain}.streetteam.site`);
                    } else if (domainOption === "custom") {
                      setDomain(`https://${customSubdomain}.${customRoot}`);
                    }
                    setSurveyStep(2);
                  }}
                >
                  Next <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                </button>
              )}

            {surveyStep === 2 && areAllLinksValid() && (
              <Button
                variant="primary"
                type="submit"
                className="bg-primary bg-gradient"
              >
                Submit
              </Button>
            )}
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default Fans;
