import { Accordion, Button, Container } from "react-bootstrap";
import UploadRelic from "../../../../musicRelics/uploadRelic/UploadRelic";
import {
  MM_CRM_LOCATION_ID,
  ST_CRM_LOCATION_ID,
} from "../../../../../integrations/Highlevel/constants";
import axios from "axios";
import { API_BASE_URL } from "../../../../../utils/constants";
import { trackCustomAction } from "../../../api";
import { toast } from "react-toastify";
import { useContext, useState, useEffect, useCallback } from "react";
import LocationContext from "../../../../../context/Locations/LocationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCog } from "@fortawesome/free-solid-svg-icons";
import { submitForm } from "../../../../../utils/submitForm";
import DescriptVideoPlayer from "../../DescriptVideoPlayer";

const relicTypes = ["moment", "song", "album"];

const Sales = ({ setCompleted }) => {
  const {locationData} = useContext(LocationContext);
  const [activeKey, setActiveKey] = useState("0"); // The default active key is 0
  const [musicRelicData, setMusicRelicData] = useState({
    moment1Id: "",
    song1Id: "",
    album1Id: "",
  });

  const [verificationStatus, setVerificationStatus] = useState({
    moment: "idle",
    song: "idle",
    album: "idle",
  });

  const SETUP_PAYMENT_INTEGRATION_LINK = `https://app.streetteam.me/v2/location/${locationData.id}/payments/integrations`;
  const SETUP_PRODUCTS_LINK = `https://app.streetteam.me/v2/location/${locationData.id}/payments/products`;
  const SETUP_WORKFLOWS_LINK = `https://app.streetteam.me/v2/location/${locationData.id}/automation/workflows`;

  const confirmAllRelics = async (moment1Id, song1Id, album1Id) => {
    let email = locationData?.owner?.email;
    if (!email || !ST_CRM_LOCATION_ID) {
      toast.error(
        "Missing email or location ID (reach out to support@modernmusician.me)"
      );
      return;
    }

    let formData = {
      location: { id: ST_CRM_LOCATION_ID },
      contact: { email },
      fields: { moment1Id, song1Id, album1Id },
    };

    try {
      console.log("Submitting sales form with fields", formData);

      const response = await axios.post(
        `${API_BASE_URL}/form/submit`, // keeping the same endpoint as you mentioned
        formData
      );
      console.log(response.data);

      if (response.data.response === "success") {
        toast.success("Music Relics created successfully");
        setMusicRelicData({
          moment1Id,
          song1Id,
          album1Id,
        });
        setActiveKey("2");
      } else {
        toast.error(
          "Something went wrong. Please try again or reach out to support@modernmusician.me."
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Something went wrong. Please try again or reach out to support@modernmusician.me"
      );
    }
  };

  const completeSalesOnboarding = async () => {
    let email = locationData?.owner?.email;

    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

    let formData = {
      location: { id: ST_CRM_LOCATION_ID },
      contact: { email },
      fields: {
        onboardingStep4: formattedDate,
      },
    };

    await submitForm(formData);

    await trackCustomAction(
      MM_CRM_LOCATION_ID,
      email,
      "ALC Step 4 Completed" // Updated as per your specification
    );
    toast.success("Completed successfully");
    setCompleted("sales", musicRelicData);
  };

  const checkAllValidated = useCallback(() => {
    return relicTypes.every((type) => verificationStatus[type] === "validated");
  }, [verificationStatus]);

  useEffect(() => {
    let pollInterval;

    if (activeKey === "10" && !checkAllValidated()) {
      // Initialize to 'in_progress' only if not already validated
      relicTypes.forEach((type) => {
        if (verificationStatus[type] !== "validated") {
          setVerificationStatus((prevStatus) => ({
            ...prevStatus,
            [type]: "in_progress",
          }));
        }
      });

      // Polling function
      const pollForWebhook = async () => {
        // Insert your logic to check webhook status
        const response = await fetch("/api/check-webhook-status");
        const data = await response.json();

        if (data.success) {
          setVerificationStatus({
            moment: data.moment_id ? "validated" : "failed",
            song: data.song_id ? "validated" : "failed",
            album: data.album_id ? "validated" : "failed",
          });
        }
      };

      // Start polling
      pollInterval = setInterval(pollForWebhook, 5000); // Poll every 5 seconds
    }

    // Cleanup
    return () => {
      if (pollInterval) clearInterval(pollInterval);
    };
  }, [activeKey, verificationStatus, checkAllValidated]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //     completeSalesOnboarding();
  // };

  return (
    <div>
      <Container
        className="my-4 d-flex justify-content-center flex-column"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #e0e0e0",
          borderRadius: "10px",
          padding: "30px",
          maxWidth: "100vw",
        }}
      >
        <h2 className="text-center mt-3">Create Your Music Relics™</h2>
        <div
          className="container d-flex flex-column mw-100"
          style={{ width: "500px" }}
        >
          <Accordion
            onSelect={(key) => setActiveKey(key)}
            className="mt-2"
            activeKey={activeKey}
            flush
          >
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <strong>1. Upload your assets.</strong>
              </Accordion.Header>
              <Accordion.Body className="d-flex justify-content-center flex-column">
                <DescriptVideoPlayer videoId="snac1M1QbIB" />
                <UploadRelic
                  setCompleted={confirmAllRelics}
                  // relicSubmissionType={"bronze"}
                />
                {/* <Button
                  variant="secondary bg-gradient border-0"
                  className="mt-3"
                  onClick={() => {
                    setActiveKey("2");
                  }}
                >
                  <FontAwesomeIcon className="me-2" icon={faUpload} />
                  Verify Relics
                </Button> */}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <strong>2. Set up payments.</strong>
              </Accordion.Header>
              <Accordion.Body className="d-flex justify-content-center flex-column">
                <DescriptVideoPlayer videoId="0s4VzXMO5L2" />
                <Button
                  variant="primary bg-gradient border-0 mt-3"
                  onClick={() => {
                    window.open(SETUP_PAYMENT_INTEGRATION_LINK, "_blank");
                  }}
                >
                  <FontAwesomeIcon className="me-2" icon={faCog} /> Open Setup
                </Button>
                <Button
                  variant="secondary bg-gradient border-0 mt-2"
                  onClick={() => {
                    setActiveKey("3");
                  }}
                >
                  <FontAwesomeIcon className="me-2" icon={faCheck} />
                  Mark as Completed
                </Button>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <strong>3. Create your products.</strong>
              </Accordion.Header>

              <Accordion.Body className="d-flex justify-content-center flex-column">
                <DescriptVideoPlayer videoId="EJHaIgM7qdP" />
                <Button
                  variant="primary bg-gradient border-0 mt-3"
                  onClick={() => {
                    window.open(
                      SETUP_PRODUCTS_LINK,
                      "_blank",
                      "width=1280,height=720"
                    );
                  }}
                >
                  <FontAwesomeIcon className="me-2" icon={faCog} /> Open Setup
                </Button>
                <Button
                  variant="secondary bg-gradient border-0 mt-2"
                  onClick={() => {
                    setActiveKey("4");
                  }}
                >
                  <FontAwesomeIcon className="me-2" icon={faCheck} />
                  Mark as Completed
                </Button>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <strong>4. Connect your workflows.</strong>
              </Accordion.Header>
              <Accordion.Body className="d-flex justify-content-center flex-column">
                <DescriptVideoPlayer videoId="BpcXMawgFKG" />
                <Button
                  variant="primary bg-gradient border-0 mt-3"
                  onClick={() => {
                    window.open(
                      SETUP_WORKFLOWS_LINK,
                      "_blank",
                      "width=1280,height=720"
                    );
                  }}
                >
                  <FontAwesomeIcon className="me-2" icon={faCog} /> Open Setup
                </Button>
                <Button
                  variant="secondary bg-gradient border-0 mt-2"
                  onClick={() => {
                    completeSalesOnboarding();
                  }}
                >
                  <FontAwesomeIcon className="me-2" icon={faCheck} />
                  Mark as Completed
                </Button>
              </Accordion.Body>
            </Accordion.Item>

            {/* <Accordion.Item eventKey="5">
              <Accordion.Header>
                <strong>4. Verify your Music Relics.</strong>
              </Accordion.Header>
              <Accordion.Body className="d-flex justify-content-center flex-column">
                <Row className="mt-2 align-items-center">
                  <Col className="d-flex flex-column align-items-center justify-content-center w-100">
                    {relicTypes.map((type) => (
                      <div key={type}>
                        {type.toUpperCase()}:&nbsp;
                        {verificationStatus[type] === "idle" && (
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        )}
                        {verificationStatus[type] === "in_progress" && (
                          <Spinner />
                        )}
                        {verificationStatus[type] === "validated" && (
                          <FontAwesomeIcon icon={faCheckCircle} />
                        )}
                        {verificationStatus[type] === "failed" && (
                          <FontAwesomeIcon icon={faTimesCircle} />
                        )}
                      </div>
                    ))}
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </div>
      </Container>
    </div>
  );
};

export default Sales;
