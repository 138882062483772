import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
//import logo from "../logo.png";
import logo from "../../artistai-logo-black.png";
import "../../App.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { websocketUrl } from "../../hooks/apiHelper";

const ChatWidget = () => {
  const chatContainerRef = useRef();

  const [logoVisible, setLogoVisible] = useState(true);

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [error, setError] = useState(null);
  const [gptModel, setGptModel] = useState("gpt-3.5-turbo");

  const socketRef = useRef();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const contactId = searchParams.get("contact_id") || "";
  const email = searchParams.get("email") || "";
  const firstName = searchParams.get("first_name") || "";
  const systemPrompt = searchParams.get("system_prompt") || "";
  const assistantName = searchParams.get("assistant_name") || ""; // Add this line
  const logoUrl = searchParams.get("logo") || ""; // Add this line
  const model = searchParams.get("model") || "";
  const iframePadding = searchParams.get("padding") === "true";
  //console.log(`iframePadding is`, iframePadding)

  const isIframe = window.self !== window.top; // This is true if the page is loaded in an iframe

  useEffect(() => {
    if (model === "power") {
      setGptModel("gpt-4");
    } else {
      setGptModel("gpt-3.5-turbo");
    }
  }, [model]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  const initializeWebsocket = useCallback((callback) =>  {

    socketRef.current = new WebSocket(
      websocketUrl
    );

    socketRef.current.onopen = () => {
      console.log("WebSocket connection opened.");
    };

    socketRef.current.onerror = (error) => {
      console.error('there is an error with this websocket connection')
      console.log("WebSocket error:", error);
      setError(error);
    };

    socketRef.current.onmessage = (event) => {
      try {
        console.log('message received');
        const message = JSON.parse(event.data);
        console.log('message is',message);
        if (
          message.messageType === "assistant_response" &&
          message.text &&
          message.text.content
        ) {
          const { content } = message.text;
          const isFinal = message.text.final;
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            const isLastMessageFromAssistant =
              lastMessage && lastMessage.sender === "assistant";
            if (isFinal) {
              if (isLastMessageFromAssistant) {
                const updatedLastMessage = {
                  ...lastMessage,
                  text: lastMessage.text + content,
                };
                return [
                  ...prevMessages.slice(0, prevMessages.length - 1),
                  updatedLastMessage,
                  { text: updatedLastMessage.text, sender: "assistant" },
                ];
              } else {
                return [
                  ...prevMessages,
                  { text: content, sender: "assistant" },
                ];
              }
            } else {
              if (isLastMessageFromAssistant) {
                const updatedLastMessage = {
                  ...lastMessage,
                  text: lastMessage.text + content,
                };
                return [
                  ...prevMessages.slice(0, prevMessages.length - 1),
                  updatedLastMessage,
                ];
              } else {
                return [
                  ...prevMessages,
                  { text: content, sender: "assistant" },
                ];
              }
            }
          });
        }
      } catch (error) {
        console.log("Error processing message:", error);
      }
    };

    if(callback){
      callback();
    }

    return () => {
      socketRef.current.close();
    };
  
  },[]);
  useEffect(() => {
    initializeWebsocket();
  }, [initializeWebsocket]);

  const inputRef = useRef();

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleToggleChange = (e) => {
    setGptModel(e.target.value);
    console.log("handleToggleChange:", e.target.value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  }, [input]);


  const sendMessage = async (e, resend) => {
    e.preventDefault();
    console.log("WebSocket readyState:", socketRef.current.readyState);

    if (input) {
      setLogoVisible(false);
      if(!resend){
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: input, sender: "user" },
        ]);
      }
      const messagePayload = {
        role: "user",
        content: input,
        previousMessages: messages.map((message) => ({
          role: message.sender,
          content: message.text,
        })),
        firstName: firstName,
        systemPrompt: systemPrompt,
        email: email,
        contact_id: contactId,
        model: gptModel,
      };
      console.log("messagePayload is", messagePayload);
      if (socketRef.current.readyState === WebSocket.OPEN) {
        // WebSocket is open, it's safe to send data
        socketRef.current.send(JSON.stringify(messagePayload));
      } else {
        // WebSocket is not in the OPEN state, handle the error or queue the data for later sending
        console.error('WebSocket is not in the OPEN state.');
        console.log(`websocket is in state`,socketRef.current.readyState)
        if(socketRef.current.readyState !== 0){
          socketRef.current.close();
          initializeWebsocket(x => sendMessage(e,true));
        }
        else {
          // Perform some action after waiting for 1 second (1000 milliseconds)
          setTimeout(() => {
            sendMessage(e,true);
          }, 3000);
        }
        // Optionally, you can queue the data and try sending it again when the WebSocket is open.
        // For simplicity, this example just logs the error.
      }
      setInput("");
  }}

  const handleSubmit = (e) => {
    sendMessage(e);
  };

  return (
    <div className="chat-widget-container">
      <Container className={isIframe &&
        !iframePadding ? "iframe-mode" : ""}>
        {model && (
          <div
            style={{
              paddingBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Form.Group>
              {console.log("Current gptModel value:", gptModel)}
              <ToggleButtonGroup
                type="radio"
                name="modelToggle"
                value={gptModel}
              >
                <ToggleButton
                  variant="outline-primary"
                  value="gpt-3.5-turbo"
                  id="turbo"
                  name="modelToggle"
                  onChange={(e) => handleToggleChange(e)}
                >
                  Turbo
                </ToggleButton>
                <ToggleButton
                  variant="outline-primary"
                  value="gpt-4"
                  id="power"
                  name="modelToggle"
                  onChange={(e) => handleToggleChange(e)}
                >
                  Power
                </ToggleButton>
              </ToggleButtonGroup>
            </Form.Group>
          </div>
        )}

        <Card
          style={{
            height: "calc(100vh - 180px)",
            overflowY: "auto",
          }}
        >
          <img
            src={logoUrl || logo}
            alt="Logo"
            className={`faded-logo ${!logoVisible ? "hidden" : ""}`}
          />
          <Card.Body ref={chatContainerRef}>
            <Row>
              <Col>
                {error && (
                  <div className="text-danger mb-3">{error.message}</div>
                )}
                <div>
                  {messages.map((message, index) => (
                    <Row className="message-row" key={index}>
                      <div className={`background-container ${message.sender}`}>
                        <div className={`message-container ${message.sender}`}>
                          <div
                            className={`chat-message ${
                              message.sender
                            }-message ${message.sender === "ai" ? "dark" : ""}`}
                          >
                            <div className="message-title">
                              <strong>
                                {message.sender === "user"
                                  ? "You: "
                                  : `${assistantName || "ArtistAI"}: `}
                              </strong>
                            </div>

                            <div className="message-content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: message.text.replace(/\n/g, "<br>"),
                                }}
                              ></p>
                            </div>
                          </div>{" "}
                          {/* replace newlines with <br> tags */}
                        </div>
                      </div>
                    </Row>
                  ))}
                </div>
                <Form onSubmit={handleSubmit} className="form-container">
                  <Form.Group className="d-flex">
                    <div className="input-text-wrapper">
                      <Form.Control
                        as="textarea"
                        rows={1}
                        ref={inputRef}
                        placeholder="Send a message..."
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        className="input-text"
                      />
                      <Button type="submit" className="submit-button">
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default ChatWidget;
