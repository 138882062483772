import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./relicHub.css";
// import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTwitter,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getDomain } from "../../../../hooks/apiHelper";
import { Spinner } from "react-bootstrap";
import ContentPlayer from "../../../streamContent/ContentPlayer";
import ActionButton from "./ActionButton";
import HubButton from "./HubButton";
// import HubButton from "./HubButton";

function ProfileComponent() {
  const { relicId, locationId } = useParams();
  const [hubData, setHubData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const apiUrl = getDomain();

  const containerRef = useRef(null);

  function sendHeightToParent() {
    if (containerRef.current) {
      let height = containerRef?.current?.offsetHeight;
      // replace "px" with an empty string
      height = height.toString().replace("px", "");
      console.log('sending height to parent',height);
      window.parent.postMessage({ frameHeight: height, source: 'hub' }, "*");
    }
  }

  useEffect(() => {
    console.log("setting up message listener");
    // The event listener function
    const receiveMessage = (event) => {
      // Add security checks such as validating the origin
      // if (event.origin !== "http://example.com") {
      //   return;
      // }

      // Handle the received message
      console.log("Received message in Relic Hub:", event.data);
      if (event?.data?.udValue && event?.data?.source ==='hub_parent') {
        const rawUserData = JSON.parse(event.data.udValue);
        console.log("rawUserData", rawUserData);
        console.log("inner circle tier", rawUserData?.inner_circle_tier);
        console.log(rawUserData);
        setUserData(rawUserData);
      }
    };

    // Register the event listener
    window.addEventListener("message", receiveMessage);

    let anon_id = localStorage.getItem("anon_id");
    if (!anon_id) {
      anon_id = "anon_" + Math.random().toString(36).substr(2, 16);
      localStorage.setItem("anon_id", anon_id);
    }
    setUserData((prev) => ({
      ...prev,
      anon_id: anon_id,
    }));

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  const handleShareClick = async () => {
    // Your link
    const link = `${
      hubData?.page?.funnel_links?.streaming || hubData?.streamingLink
    }?mx_ref_id=${userData?.id || hubData?.contact?.id}`;

    // Copying link to clipboard
    try {
      await navigator.clipboard.writeText(link);
      setIsCopied(true);

      // Optional: Reset button text after 3 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      console.log("userData", userData);
      // getHubData(relicId, locationId, userData?.id);
    }
  }, [userData]);

  const getHubData = useCallback(
    async (relicId, locationId, contactId) => {
      // console.log(`getting data`);
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/relics/hub?relic=${relicId}&location=${locationId}&contact=${contactId}`
      );
      setLoading(false);
      const newData = response?.data?.data;
      if (newData) {
        const { contact, relic, page } = newData;
        setHubData((prev) => ({
          ...prev,
          contact: { ...prev?.contact, ...contact },
          relic,
          page,
        }));
      }
    },
    [apiUrl]
  );
  useEffect(() => {
    console.log("userData", userData);
    let contact = userData?.id;
    console.log(`relic:${relicId},contact:${contact},location:${locationId}`);
    if (relicId && locationId) {
      getHubData(relicId, locationId, contact);
    }
  }, [relicId, getHubData, locationId, userData]);

  useEffect(() => {
    if (hubData?.relic) {
      // The relic data is loaded, send updated height to parent
      sendHeightToParent();
    }
  }, [hubData?.relic]);

  // console.log("hub data", hubData);
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex justify-content-center align-items-center vh-100"
      >
        <Spinner className="text-white" />
      </Container>
    );
  }

  function toSentenceCase(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }

  const tokens = hubData?.contact?.customField?.find(
    (item) => item.fieldKey === "contact.tokens"
  )?.value;
  const profilePicture = hubData?.contact?.customField?.find(
    (item) => item.fieldKey === "contact.profile_picture"
  )?.value?.url;
  const innerCircleTier = hubData?.contact?.customField?.find((item) => item.fieldKey === "contact.inner_circle_tier")?.value || userData?.inner_circle_tier || userData?.subscription_tier || "New"
  const innerCircleUrl = hubData?.page?.funnel_links?.inner_circle || hubData?.page?.funnel_links?.inner_circle_portal || hubData?.page?.custom_values?.["Inner Circle Portal Link"]
  const relicsRemaining =  hubData?.relic?.type ? hubData?.page?.custom_values?.[`${toSentenceCase(hubData?.relic?.type)} 1 Remaining`] : hubData?.relic?.remaining || 95;
  const relicEditionSize = hubData?.relic?.edition_size || hubData?.page?.custom_values?.[`${toSentenceCase(hubData?.relic?.type)} 1 Edition Size`] || 500;
  const facebookLink = hubData?.page?.funnel_links?.social_facebook || hubData?.page?.custom_values?.[" Social Facebook Link"];
  const instagramLink = hubData?.page?.funnel_links?.social_instagram || hubData?.page?.custom_values?.[" Social Instagram Link"];
  const youtubeLink = hubData?.page?.funnel_links?.social_youtube || hubData?.page?.custom_values?.[" Social Youtube Link"];
  const twitterLink = hubData?.page?.funnel_links?.social_twitter || hubData?.page?.custom_values?.[" Social Twitter Link"];
  const tiktokLink = hubData?.page?.funnel_links?.social_tiktok || hubData?.page?.custom_values?.[" Social TikTok Link"];

  return (
    <Container
      ref={containerRef}
      className="profile-container d-flex justify-content-between align-items-between flex-column gap-4 py-5 px-4 h-100"
    >
      <Row>
        <Col className="text-center">
          <img
            src={
              profilePicture || hubData?.contact?.profileImage || userData?.profile_picture || 
              "https://storage.googleapis.com/msgsndr/ZxaXDE2BkNQQ1H0xq2vR/media/6539e719cc8c1270ee1aad05.png"
            }
            alt="Profile"
            className="profile-image"
          />
        </Col>
      </Row>
      <Row className="text-center">
        <Col>
          <h1 className="hub-h1">
            {userData?.fullName || hubData?.contact?.firstName || userData?.full_name || "You"}{" "}
            {!userData?.fullName && hubData?.contact?.lastName}
          </h1>
          <p className="hub-p m-0">
            {innerCircleTier} {" Fan"} | {hubData?.page?.custom_values?.["Artist Name"] ||
              hubData?.artistName || 
              "Artist"}
          </p>
          <p className="hub-p m-0 fs-7">
            {tokens > 0 ? (
              <>
                <strong className="fw-500"> {tokens}</strong> tokens available
              </>
            ) : (
              "You have 0 tokens available"
            )}
          </p>

          {/* <a href="#" className="edit-profile-link">Edit Profile</a> */}
        </Col>
      </Row>
      {hubData?.relic && (
        <Card style={{ width: '100%', borderColor: '#333', backgroundColor: 'black', color: '#fff' }}>
        <Card.Body>
        <ContentPlayer
              contentType="relic"
              contentData={hubData?.relic}
              locked={userData.id ? false : true}
            />
        <Card.Footer className="d-flex justify-content-between" style={{ width: '100%', borderColor: '#333', backgroundColor: 'transparent', color: '#fff'  }}>
        <div><div style={{ fontSize: '18px' }}>Initial Value</div><div>{relicEditionSize} minted</div></div>
        <div><div style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'right' }}>${hubData.relic.price || 10}</div><div style={{ textAlign: 'right' }}>{relicsRemaining } remaining </div></div>
        </Card.Footer>
      </Card.Body>
      </Card>
      )}
      <Row className="text-center">
        <Col>
          <ActionButton
            type="referral"
            // variant="secondary"
            points={100}
            onClick={handleShareClick}
          >
            {isCopied ? "Copied!" : "Share With a Friend"}
          </ActionButton>{" "}
        </Col>
      </Row>
      {innerCircleUrl && (
        <Row className="text-center">
          <Col>
            <HubButton type="enter" points={100} href={innerCircleUrl} target="_blank" rel="noopener noreferrer">
              Enter Inner Circle Portal
            </HubButton>
          </Col>
        </Row>
      )}
      <Row className="social-icons text-center">
        <Col>
          <a
            href={facebookLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon style={{ color: facebookLink ? 'white' : 'gray'}} icon={faFacebookF} />
          </a>
        </Col>
        <Col>
          <a
            href={instagramLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon style={{ color: instagramLink ? 'white' : 'gray'}}  icon={faInstagram}/>
          </a>
        </Col>
        <Col>
          <a
            href={youtubeLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon style={{ color: youtubeLink ? 'white' : 'gray'}} icon={faYoutube} />
          </a>
        </Col>
        <Col>
          <a
            href={twitterLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon style={{ color: twitterLink ? 'white' : 'gray'}} icon={faTwitter} />
          </a>
        </Col>
        <Col>
          <a
            href={tiktokLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon style={{ color: tiktokLink ? 'white' : 'gray'}} icon={faTiktok} />
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfileComponent;
