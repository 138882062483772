import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

const MOCK_USER_DATA = {
  inner_circle_tier: "Gold",
  inner_circle_tier_number: 3,
  full_name: "Michael Walker",
  first_name: "Michael",
  last_name: "Walker",
  id: "LzTWylv8Z9WoCYmyD1pt",
  tokens: 500,
  profile_picture:
    "https://services.leadconnectorhq.com/documents/download/BEoXzC4614gvyX3KkgsY",
  mx_ref_id: "NW3TEBP3ICsXqJLb3tTo",
  email: "michael.walker@modernmusician.me",
};

const RelicHubPreview = () => {
  const { locationId, relicId } = useParams();
  const containerRef = useRef(null);
  const [iframeId] = useState(
    `streaming_${crypto.randomUUID().substring(0, 6)}`
  );

  useEffect(() => {
    if (!containerRef.current) return;
    if (containerRef.current.querySelector("iframe")) return;

    const stageUrl = "https://localhost:3000";

    // Create iframe
    const iframe = document.createElement("iframe");
    iframe.id = iframeId;
    iframe.style.border = "none";
    iframe.style.height = "600px";
    iframe.allow = "geolocation; microphone; camera; clipboard-write";
    iframe.src = `${stageUrl}/hub/${locationId}/${relicId}`;
    iframe.width = "100%";

    containerRef.current.appendChild(iframe);

    // After the iframe is appended, you can use it for other logic.
    const relicIframe = document.getElementById(iframeId);

    function postMessageToIFrame(message) {
      relicIframe.contentWindow.postMessage(message, "*"); // Consider replacing '*' with the exact target origin for better security
    }

    relicIframe.addEventListener("load", function () {
      // const udValue = localStorage.getItem("_ud");
      const udValue = JSON.stringify(MOCK_USER_DATA);
      console.log("preparing to send message", udValue);
      setTimeout(() => {
        if (udValue) {
          postMessageToIFrame({ udValue, source:'hub_parent' });
        }
      }, 500); // delay, can be adjusted
    });

    window.addEventListener(
      "message",
      function (event) {
        console.log('event is',event);
        if (event.data.frameHeight && event.data.source==='hub') {
          console.log('setting relicIframe style.height');
          console.log('relicIframe',relicIframe);
          console.log('relicIframe.style.height',relicIframe.style.height);
          console.log('new event.data.frameHeight',event.data.frameHeight);
          relicIframe.style.height = event.data.frameHeight + "px";
        } else if(event.data.source==='hub') {
          relicIframe.style.height = "600px"; // Default height
        }
      },
      false
    );

    return () => {
      // Cleanup: Remove the event listeners when the component is unmounted.
      relicIframe.removeEventListener("load");
      window.removeEventListener("message");
    };
  }, [iframeId, locationId, relicId]);

  return (
    <Container fluid className="vh-100 bg-gray pt-5">
      <Container
        ref={containerRef}
        className="d-flex justify-content-center"
        style={{ opacity: 0.8, backgroundColor: "red", maxWidth: "600px" }}
      >
        {/* Additional content */}
      </Container>
    </Container>
  );
};

export default RelicHubPreview;
