import axios from "axios";
import { API_BASE_URL } from "../../../../../utils/constants";

// create ARTIST_TIERS constant with { name: "Emerging Artist", value: 97 } and so on
const ARTIST_TIERS = [
  { name: "Emerging Artist", mrr: 97, ascension_milestone: 100 },
  { name: "Rising Artist", mrr: 297, ascension_milestone: 1000 },
  { name: "Gold Artist", mrr: 997, ascension_milestone: 10000 },
  { name: "Platinum Artist", mrr: 2997, ascension_milestone: 100000 },
  { name: "Diamond Artist", mrr: 9997, ascension_milestone: 1000000 },
];

function parseMinimumNumberFromString(str) {
  // This regular expression finds the first group of digits,
  // possibly separated by commas, and preceded by a $ sign.
  const regex = /\$([0-9,]+)/;
  let match = str.match(regex);

  // Remove the $ sign and commas, then convert to a number
  return match ? parseInt(match[1].replace(/[$,]/g, ""), 10) : null;
}

function calculateArtistTier(
  resourceful,
  investable_budget,
  monthly_revenue_options
) {
  let monthly_revenue = parseMinimumNumberFromString(monthly_revenue_options);

  console.log(
    `Calculating artist tier with resourceful: ${resourceful}, investable_budget: ${investable_budget}, monthly_revenue: ${monthly_revenue}`
  );
  if (resourceful === "No") {
    // Logic based on investable budget
    console.log(
      "Resourceful is No, determining tier based on investable budget"
    );
    return assignTierBasedOnBudget(investable_budget);
  } else {
    // Logic based on monthly revenue with a minimum tier of Gold Artist
    console.log(
      "Resourceful is Yes, determining tier based on monthly revenue with a minimum tier of Gold Artist"
    );

    let minimumTier = ARTIST_TIERS[2]; // Gold Artist
    if (monthly_revenue < minimumTier.ascension_milestone) {
      console.log(
        `Monthly revenue is less than ${minimumTier.ascension_milestone}, assigning to ${minimumTier.name}`
      );
      return minimumTier;
    } else {
      console.log(
        `Monthly revenue is greater than ${minimumTier.ascension_milestone}, assigning to tier based on revenue`
      );
      return assignTierBasedOnRevenue(monthly_revenue);
    }
  }
}

function assignTierBasedOnBudget(budget) {
  console.log(`Assigning tier based on budget: ${budget}`);
  if (budget < 300) {
    console.log(`Assigned to:`, ARTIST_TIERS[0]);
    return ARTIST_TIERS[0]; // Emerging Artist
  }
  if (budget < 1000) {
    console.log(`Assigned to:`, ARTIST_TIERS[1]);
    return ARTIST_TIERS[1]; // Rising Artist
  }
  if (budget < 3000) {
    console.log(`Assigned to:`, ARTIST_TIERS[2]);
    return ARTIST_TIERS[2]; // Gold Artist
  }
  if (budget < 10000) {
    console.log(`Assigned to:`, ARTIST_TIERS[3]);
    return ARTIST_TIERS[3]; // Platinum Artist
  }
  console.log(`Assigned to:`, ARTIST_TIERS[4]);
  return ARTIST_TIERS[4]; // Diamond Artist
}

function assignTierBasedOnRevenue(revenue) {
  console.log(`Assigning tier based on revenue: ${revenue}`);
  if (revenue < 100) {
    console.log(`Assigned to:`, ARTIST_TIERS[0]);
    return ARTIST_TIERS[0]; // Emerging Artist
  }
  if (revenue < 1000) {
    console.log(`Assigned to:`, ARTIST_TIERS[1]);
    return ARTIST_TIERS[1]; // Rising Artist
  }
  if (revenue < 10000) {
    console.log(`Assigned to:`, ARTIST_TIERS[2]);
    return ARTIST_TIERS[2]; // Gold Artist
  }
  if (revenue < 100000) {
    console.log(`Assigned to:`, ARTIST_TIERS[3]);
    return ARTIST_TIERS[3]; // Platinum Artist
  }
  console.log(`Assigned to:`, ARTIST_TIERS[4]);
  return ARTIST_TIERS[4]; // Diamond Artist
}

function calculateResponsivenessScore(artist_description, roadblocks) {
  // Simple word count approach
  let descriptionLength = wordCount(artist_description);
  let roadblocksLength = wordCount(roadblocks);

  // Assume a scoring system where length corresponds to a score
  let score = (descriptionLength + roadblocksLength) / 2; // Average word count

  // Scale score to a range of 0 to 1 (or 0% to 100%)
  return Math.min(score / 1000, 1); // Assuming 1000 words is the maximum for full score
}

function wordCount(text) {
  return text.trim().split(/\s+/).length;
}

async function getQualitativeScore(application) {
  // Placeholder function that would interface with the AI summarizer
  // You would convert the summarizer's output into a numeric score
  let summarizerOutput = await generateTalentScoutSummary(application);
  let score = convertSummarizerOutputToScore(summarizerOutput);

  // For now, let's return a dummy value
  return score;
}

function convertSummarizerOutputToScore(output) {
  // Logic to convert AI summarizer's output to a score
  // You might need to determine how you will quantify the summarizer's output

  // This is a placeholder, actual implementation will depend on the AI's output
  return 0.5; // Example fixed score
}

function calculateConversionRate(responsivenessScore, qualitativeScore) {
  // Combine responsiveness and qualitative scores
  // A simple average might not be ideal, consider weighted averages or other formulas

  let combinedScore = (responsivenessScore + qualitativeScore) / 2;

  // Convert combined score to a conversion rate
  // For example, if combined score is 0.5, let's say conversion rate is 5%
  let conversionRate = combinedScore * 0.1; // Scaled for example purposes

  return conversionRate;
}

function calculateOpportunityValue(application) {
  let tierValue = calculateArtistTier(
    application.resourceful,
    application.investable_budget,
    application.monthly_revenue
  );
  let totalDealValue = tierValue * 10; // Constant multiplier for deal value
  let responsivenessScore = calculateResponsivenessScore(
    application.artist_description,
    application.roadblocks
  );
  let qualitativeScore = getQualitativeScore(application); // Needs to be defined based on AI summarizer output
  let predictedConversionRate = calculateConversionRate(
    responsivenessScore,
    qualitativeScore
  );
  let opportunityValue = totalDealValue * predictedConversionRate;

  return opportunityValue;
}

const generateTalentScoutSummary = async (formData) => {
  // fetch with axios from our api with the formData in the body to endpoint /ai/talent-scout-summary
  const response = await axios.post(
    `${API_BASE_URL}/ai/talent-scout-summary`,
    formData
  );

  console.log("response.data:", response.data);

  return response.data;
};

// export multiple functions like this
export {
  calculateOpportunityValue,
  generateTalentScoutSummary,
  calculateArtistTier,
};

// export default calculateOpportunityValue;
