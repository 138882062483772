// import { params } from "@ampt/sdk";
const HIGHLEVEL_API_BASE_URL = `https://rest.gohighlevel.com`;

const MM_CRM_LOCATION_ID = "40w74OIRtBJaXkcwkVNQ";
const ST_CRM_LOCATION_ID = "ZxaXDE2BkNQQ1H0xq2vR";
// const MM_CRM_API_KEY = params("KEY_GHL_MM");
// const ST_CRM_API_KEY = params("KEY_GHL_ST");
// const HIGHLEVEL_AGENT_TOKEN = params("KEY_GHL_AGENT");

export {
    HIGHLEVEL_API_BASE_URL,
    MM_CRM_LOCATION_ID,
    // MM_CRM_API_KEY,
    ST_CRM_LOCATION_ID,
    // ST_CRM_API_KEY,
    // HIGHLEVEL_AGENT_TOKEN,
};
    
