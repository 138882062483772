import axios from "axios";

const TestApiAuth = () => {
  const testAuth = () => {
    axios
      .get("https://fearless-deploy-p7bch.ampt.app/testing-cookie", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
      });
  };

  return (
    <div>
      <button onClick={testAuth}>Test Auth</button>
    </div>
  );
};

export default TestApiAuth;
