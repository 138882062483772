import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  OverlayTrigger,
  Tooltip as BSTooltip,
  Spinner,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const formatTick = (tick) => `$${tick}`;

function formatDateDDMMM(dateString) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const monthIndex = date.getMonth();
  const month = months[monthIndex];

  return `${day} ${month}`;
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          border: "1px solid #cccc",
          borderRadius: "5px",
          paddingRight: "1rem",
          paddingLeft: "1rem",
          color: "#000000",
          fontWeight: "300",
          fontSize: "1.5vw", // responsive font size
          //display: "flex",
          //flexDirection: "column",
          boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.16)", // Subtle box shadow
        }}
      >
        <p
          className="label"
          style={{
            marginBottom: "-1rem",
            fontSize: "0.85rem",
            textAlign: "left",
          }}
        >{`${formatDateDDMMM(label)}`}</p>
        <p className="intro" style={{ textAlign: "left" }}>
          <span style={{ fontSize: "0.85rem" }}>Usage:</span>
          <span
            style={{ fontWeight: "500", fontSize: "0.85rem" }}
          >{`  $${payload[0].value}`}</span>
        </p>
      </div>
    );
  }
  return null;
};

const UsageDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const artistId = searchParams.get("crm_location_id") || "";

  // Get the current domain
  let domain = window.location.hostname.includes("localhost")
    ? process.env.REACT_APP_LOCAL_DOMAIN
    : window.location.origin;
  console.log("domain is", domain);

  useEffect(() => {
    let locationDataUrl = `${domain}/data/locations/${artistId}`;
    console.log("locationDataUrl is", locationDataUrl);
    const fetchData = async () => {
      try {
        console.log("looking for data");
        const response = await axios.get(locationDataUrl);
        console.log("response is", response);
        setData(response.data.object.config.artist_ai);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [artistId, domain]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  const { billing, usage } = data;

  const progress = (usage.total_user_cost / billing.hard_limit) * 100;

  const chartData = usage.history.daily.map((item) => ({
    date: formatDateDDMMM(item.date), // formatting the date
    cost: Number(item.user_cost.toFixed(2)), // formatting the cost
  }));

  const currentUsageTooltip = (
    <BSTooltip id="current-usage-tooltip">
      <h3>{`$${usage.total_user_cost.toFixed(2)}`}</h3>
      <p style={{ fontWeight: "lighter" }}>Total usage so far this month.</p>
    </BSTooltip>
  );

  const hardLimitTooltip = (
    <BSTooltip id="hard-limit-tooltip">
      <h3>{`$${billing.hard_limit.toFixed(2)}`}</h3>
      <p style={{ fontWeight: "lighter" }}>
        Your billing hard limit, as configured in your Billing settings.
      </p>
    </BSTooltip>
  );

  return (
    <Container style={{ padding: "2em", maxWidth: "800px" }}>
      <Row style={{ marginBottom: "1em" }}>
        <h1>Usage</h1>
        <p>
          Below you'll find a summary of your ArtistAI usage for your account.
          All dates and times are UTC-based, and data may be delayed up to 5
          minutes.
        </p>

        {/* Here, you can add the month toggle component */}
      </Row>
      <h6 style={{ marginBottom: "1em", fontWeight: "600" }}>
        Daily usage (USD)
      </h6>

      <div style={{ height: 300, marginLeft: "-35px" }}>
        <ResponsiveContainer>
          <BarChart data={chartData} margin={{ top: 5, bottom: 5 }}>
            <CartesianGrid strokeDasharray="0 0" vertical={false} />
            <XAxis dataKey="date" />
            <YAxis tickFormatter={formatTick}></YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="cost" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <h3 style={{ marginTop: "1em" }}>Usage this month</h3>
      <Row style={{ marginBottom: "1em", alignItems: "center" }}>
        <Col xs={12} md={9} style={{ marginTop: "1em" }}>
          <ProgressBar now={progress} variant="info" />
        </Col>
        <Col
          xs={12}
          md={3}
          style={{
            display: "flex",
            justifyContent: "space-around",
            textAlign: "right",
            marginTop: "1em",
          }}
        >
          <OverlayTrigger placement="bottom" overlay={currentUsageTooltip}>
            <span style={{ borderBottom: "1px dotted #000" }}>
              ${usage.total_user_cost.toFixed(2)}
            </span>
          </OverlayTrigger>
          <div> / </div>
          <OverlayTrigger placement="bottom" overlay={hardLimitTooltip}>
            <span style={{ borderBottom: "1px dotted #000" }}> ${billing.hard_limit.toFixed(2)}</span>
          </OverlayTrigger>
        </Col>
      </Row>
    </Container>
  );
};

export default UsageDashboard;

//   <Row>
//     <Col>
//       <Card style={{ marginBottom: "1em" }}>
//         <Card.Header>Artist AI Config</Card.Header>
//         <Card.Body>
//           <Card.Text>System Prompt: {system_prompt}</Card.Text>
//           <Card.Text>Text Transform: {text_transform}</Card.Text>
//         </Card.Body>
//       </Card>
//       <Card style={{ marginBottom: "1em" }}>
//         <Card.Header>Billing</Card.Header>
//         <Card.Body>
//           <Card.Text>
//             Credits Remaining: {billing.credits_remaining}
//           </Card.Text>
//           <Card.Text>Recharge Amount: {billing.recharge_amount}</Card.Text>
//           <Card.Text>Monthly Spend: {billing.monthly_spend}</Card.Text>
//         </Card.Body>
//       </Card>
//     </Col>
//     <Col>
//       <Card style={{ marginBottom: "1em" }}>
//         <Card.Header>Usage</Card.Header>
//         <Card.Body>
//           <Card.Text>Total Tokens: {usage.total_tokens}</Card.Text>
//           <Card.Text>Total Cost: {usage.total_user_cost}</Card.Text>
//           <Card.Text>
//             Unique Conversations: {usage.unique_conversations}
//           </Card.Text>
//         </Card.Body>
//       </Card>
//       <Card style={{ marginBottom: "1em" }}>
//         <Card.Header>Daily Usage</Card.Header>
//         <Card.Body>
//           {usage.history.daily.map((dailyUsage, index) => (
//             <div key={index}>
//               <h5>{dailyUsage.date}</h5>
//               <p>Tokens Cost: {dailyUsage.tokens_cost}</p>
//               <p>User Cost: {dailyUsage.user_cost}</p>
//               <p>Unique Conversations: {dailyUsage.unique_conversations}</p>
//             </div>
//           ))}
//         </Card.Body>
//       </Card>
//     </Col>
//   </Row>;
