//import scss file
import { useContext } from "react";
import "../../../../../App.scss";
import UploadSong from "../../../../uploadTrack/UploadSong";
import LocationContext from "../../../../../context/Locations/LocationContext";
import { toast } from "react-toastify";
import {
  MM_CRM_LOCATION_ID,
  // MM_CRM_LOCATION_ID,
  ST_CRM_LOCATION_ID,
} from "../../../../../integrations/Highlevel/constants";
import axios from "axios";
import { API_BASE_URL } from "../../../../../utils/constants";
import { trackCustomAction } from "../../../api";
// import ContentPlayer from "../../streamContent/ContentPlayer";
// import { Button } from "react-bootstrap";
// import { trackCustomAction } from "../api";

const Streams = ({ setCompleted }) => {
  const {locationData} = useContext(LocationContext);

  // let contentId = locationData?.custom_values?.["Primary Track ID"];

  const completeStreamOnboarding = async (trackId) => {
    let email = locationData?.owner?.email;
    if (!email || !ST_CRM_LOCATION_ID) {
      toast.error(
        "Missing email or location ID (reach out to support@modernmusician.me)"
      );
      return;
    }

    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

    let formData = {
      location: { id: ST_CRM_LOCATION_ID },
      contact: { email },
      fields: {
        primaryTrackId: trackId,
        onboardingStep2: formattedDate,
      },
    };

    try {
      console.log("Submitting form with formData", formData);
      // setIsSubmitting(true);

      const response = await axios.post(
        `${API_BASE_URL}/form/submit`,
        formData
      );
      console.log(response.data);

      if (response.data.response === "success") {
        setCompleted("streams", formData.fields);
        await trackCustomAction(
          MM_CRM_LOCATION_ID,
          email,
          "ALC Step 2 Completed"
        );
        toast.success("Completed successfully");
      } else {
        // Handle unsuccessful form submission if needed
        toast.error(
          "Something went wrong. Please try again or reach out to support@modernmusician.me."
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Something went wrong. Please try again or reach out to support@modernmusician.me"
      );
    }
  };

  return (
    <div>
      {locationData?.custom_values?.["Primary Track ID"] ? (
        <UploadSong setCompleted={completeStreamOnboarding} />
      ) : (
        // <div>
        //   {" "}
        //   Primary Track is {
        //     locationData?.custom_values?.["Primary Track ID"]
        //   }{" "}
        //   <ContentPlayer contentData={{ contentId }} contentType={"track"}> </ContentPlayer>
        //   <Button > Edit </Button>
        // </div>
        <UploadSong setCompleted={completeStreamOnboarding} />
      )}
    </div>
  );
};

export default Streams;
