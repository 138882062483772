import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Spinner,
} from "react-bootstrap";
import { uploadFile } from "../../utils/uploadFile";
import { useSearchParams } from "react-router-dom";
import { postContentData } from "../musicRelics/utils";
import { getDomain } from "../../hooks/apiHelper";
import LocationContext from "../../context/Locations/LocationContext";
import { ToggleFileDisplay } from "../adCreator/MomentUpload";
const { v4: uuidv4 } = require("uuid");

export default function UploadSong({ setCompleted }) {
  const [trackData, setTrackData] = useState({});
  const [audioFile, setAudioFile] = useState(null);
  const [artworkFile, setArtworkFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(null);
  const {locationData, setLocationData} = useContext(LocationContext);
  console.log('trackData',trackData);
  //steps of the form
  const steps = useMemo(
    () => [
      { action: "upload", name: "Submit Track" },
      { action: "copy", name: "Copy Link 📋" },
    ],
    []
  );
  const [step, setStep] = useState(steps[0]);

  const [searchParams] = useSearchParams();
  const trackTitleRef = useRef(null);

  const setFormDefault = useCallback(() => {
    const contentId = uuidv4().replace(/-/g, "").substring(0, 25);
    const audioId = `audio_${contentId}`;
    const imageId = `image_${contentId}`;
    const trackId = `track_${contentId}`;
    const locationId = locationData?.id;
    const creatorId=`creator_${locationId}`;
    const artistName = locationData?.name || searchParams.get("artist_name");
    const email = locationData?.owner?.email;
    const title = searchParams.get("title") || "";
    setArtworkFile(null);
    setAudioFile(null);
    setUploadPercentage(0);
    setTrackData((prevData) => ({
      track:{
        id:trackId,
        locationId: locationId,
        title:title,
        creator: {
          id: creatorId,
          name: artistName,
          email: email
        },
        audioId: audioId,
        imageId: imageId, // might want to wait to set this until we confirm the content is created?
      },
      audioContent:{
        id: audioId,
        creator: {
          id: creatorId,
          name: artistName,
          email: email,
        },
        title: title,
      },
      imageContent:{
        id: imageId,
        creator: {
          id: creatorId,
          name: artistName,
          email: email,
        },
        title: title,
      },
    }));
  }, [setTrackData, searchParams, locationData]);

  useEffect(() => {
    if (trackTitleRef.current) {
      trackTitleRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setFormDefault();
  }, [setFormDefault]);

  const handleFileChange = (e, type) => {
    if (type === "audio") setAudioFile(e.target.files[0]);
    else if (type === "artwork") setArtworkFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      setIsUploading(true);
      let uploadAudioResponse, uploadArtworkResponse;
      if (audioFile) {
        const name = audioFile?.name;
        const ext = name.split(".")[name.split(".").length - 1];
        const fileName = `${trackData.track.id}_audio.${ext}`;
        uploadAudioResponse = await uploadFile(
          audioFile,
          trackData.track.creator.id,
          fileName,
          function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded / progressEvent.total / 2) * 100
            );
            setUploadPercentage(percentCompleted);
          }
        );
        console.log(`uploadAudioResponse`, uploadAudioResponse);
      }
      if (artworkFile) {
        const name = artworkFile?.name;
        const ext = name.split(".")[name.split(".").length - 1];
        const fileName = `${trackData.trackId}_artwork.${ext}`;
        uploadArtworkResponse = await uploadFile(
          artworkFile,
          trackData.track.creator.id,
          fileName,
          function (progressEvent) {
            const percentCompleted = Math.round(
              50 + (progressEvent.loaded / progressEvent.total / 2) * 100
            );
            setUploadPercentage(percentCompleted);
          }
        );
        console.log(`artwork uploadResponse`, uploadArtworkResponse);
      }
      setTrackData((prevData) => ({
        ...prevData,
        audioContent: {
          ...prevData.audioContent,
          url: uploadAudioResponse?.publicUrl,
        },
        imageContent: {
          ...prevData.imageContent,
          url:uploadArtworkResponse?.publicUrl,
        }
      }));
      if (uploadAudioResponse?.publicUrl && uploadArtworkResponse?.publicUrl) {
        const dataResponse = await postContentData({
          ...trackData,
          audioContent: {
            ...trackData.audioContent,
            url: uploadAudioResponse?.publicUrl,
          },
          imageContent: {
            ...trackData.imageContent,
            url: uploadArtworkResponse?.publicUrl,
          }
        });
        console.log(`dataReponse is`, dataResponse);
      }
      setIsUploading(false);
      // setCompleted("streams");
    } catch (error) {
      console.error("Error:", error);
      setIsUploading(false);
    }
  };

  const copyLink = useCallback(async () => {
    console.log("copying link");
    const trackId = trackData.track.id;
    const url = `${getDomain()}/streaming/track/${trackId}`;

    navigator.clipboard
      .writeText(url)
      .then(() => {
        setStep({ action: "done", name: "Copied!" });
        setTimeout(() => {
          setStep(steps[1]);
        }, 5000);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  }, [trackData, setStep, steps]);

  const handleStepAction = async () => {
    console.log(`at time of invalidFormCheck`, trackData, audioFile, artworkFile);
    if (step.action === "upload") {
      // Check for empty fields
      if (
        !trackData?.track?.title ||
        !trackData?.track?.creator.name ||
        !audioFile ||
        !artworkFile
      ) {
        alert("Please fill in all fields.");
        return;
      }
      await handleUpload();
    } else if (step.action === "copy") {
      await copyLink();
    }
    const currentStepIndex = steps.findIndex((i) => i.action === step.action);
    if (currentStepIndex < steps.length - 1) {
      console.log(`setting next step`);
      setStep(steps[currentStepIndex + 1]);
    }

    console.log(`trackData is`, trackData);

    setLocationData({
      ...locationData,
      tracks: {
        ...locationData.tracks,
        primary: trackData?.track.id,
      },
    })

    setCompleted(trackData?.track.id);

  };

  return (
    <Container
      className="my-4 d-flex flex-column justify-content-center align-items-center gap-3" // Added flexbox centering
      style={{
        backgroundColor: "#fff",
        border: "1px solid #e0e0e0",
        paddingTop: "30px",
        paddingBottom: "30px",
        borderRadius: "10px",
      }}
    >
      <h3 className="my-2"> Upload a Track </h3>

      {Object.keys(trackData).length === 0 ? (
        <Spinner />
      ) : (
        <Col xs={12} md={10} lg={8} xl={6}>
          {" "}
          {/* Adjusted grid settings */}
          <Card className="px-3 py-2 d-grid gap-2" style={{ border: "none" }}>
            {" "}
            {/* Removed border */}
            <Form className="d-grid gap-2">
              <Form.Group as={Row} controlId="formTitle" className="mb-3">
                <Form.Label column sm="2">
                  Title
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    ref={trackTitleRef}
                    type="text"
                    placeholder="Title"
                    value={trackData?.track?.title || ""}
                    onChange={(e) =>
                      setTrackData((prevData) => ({
                        ...prevData,
                        track: { ...prevData.track, title: e.target.value },
                      }))
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formArtist" className="mb-3">
                <Form.Label column sm="2">
                  Artist
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Artist"
                    value={trackData?.track?.creator?.name || ""}
                    onChange={(e) =>
                      setTrackData((prevData) => ({
                        ...prevData,
                        track: {
                          ...prevData.track,
                          creator: {
                            ...prevData.track.creator,
                            'name':e.target.value
                          }
                        },
                        audioContent: {
                          ...prevData.audioContent,
                          creator: {
                            ...prevData.audioContent.creator,
                            'name':e.target.value
                          }
                        },
                        imageContent: {
                          ...prevData.imageContent,
                          creator: {
                            ...prevData.audioContent.creator,
                            'name':e.target.value
                          }
                        }
                      }))
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Audio
                </Form.Label>
                <Col sm="10">
                  <ToggleFileDisplay
                    name="audio"
                    file={audioFile}
                    accept=".mp3, audio/wav, audio/x-aiff, audio/x-m4a"
                    handleFileChange={(e) => handleFileChange(e, "audio")}
                    clearFile={(name) => {
                      if (name === "audio") setAudioFile(null);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Artwork
                </Form.Label>
                <Col sm="10">
                  <ToggleFileDisplay
                    name="artwork"
                    accept="image/*"
                    file={artworkFile}
                    handleFileChange={(e) => handleFileChange(e, "artwork")}
                    clearFile={(name) => {
                      if (name === "artwork") setArtworkFile(null);
                    }}
                  />
                </Col>
              </Form.Group>

              <Button onClick={handleStepAction} disabled={isUploading}>
                {isUploading ? <Spinner /> : step.name}
              </Button>
              {uploadPercentage > 0 && uploadPercentage < 100 && (
                <ProgressBar
                  now={uploadPercentage}
                  label={`${uploadPercentage}%`}
                  variant={uploadPercentage === 100 ? "success" : "loading"} //for this custom css to work, we need to create classes for it
                  className="mb-3"
                />
              )}
            </Form>
          </Card>
        </Col>
      )}
    </Container>
  );
}
