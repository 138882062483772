// import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "./constants";

export const submitForm = async ({ location, contact, fields }) => {
  if (!location || !contact) {
    toast.error("Missing location ID or contact ID/email");
    return;
  }

  try {
    const response = await axios.post(`${API_BASE_URL}/form/submit`, {
      location,
      contact,
      fields,
    });
    console.log(response.data);

    if (response.data.response === "success") {
      // toast.success("Form submission was successful");
    } else {
      // toast.error(
      //   "Something went wrong. Please try again or reach out to support."
      // );
    }
  } catch (error) {
    console.error(`Error submitting form`, error);
    toast.error(
      `An error occurred while submitting the form. Please try again later or reach out to support. Error is ${error}`
    );
  }
};
